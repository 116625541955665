import React from 'react'

const CovideoIcon = props => {
  const height = props.height ? props.height : '24px'
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 266 240"
      style={{ enableBackground: 'new 0 0 266 240' }}
      height={height}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M115.133 187.119V51.9296L62.157 18.1917L0 79.99V159.98L62.157 222.195L115.133 187.119Z"
        fill="#1C8578"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M183.756 118.008L266 117.652V79.9901L185.957 0H80.5202L62.2041 18.1917L183.756 118.008Z"
        fill="url(#paint0_linear_2_4280)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M183.756 118.009L62.2041 222.195L80.0147 240H185.957L266 159.98V117.652L183.756 118.009Z"
        fill="#11A694"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_4280"
          x1="139.697"
          y1="12.5309"
          x2="208.851"
          y2="116.196"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0483" stop-color="#11A694" />
          <stop offset="1" stop-color="#2FE0C7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default CovideoIcon
