import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import RichTextEditor from 'react-rte'

import Modal from '../../layouts/modal'
import ToolTip from '../../helpers/tooltip'

import {
  addTemplate,
  createEmailTemplate,
  changeTemplateType,
  renderEmailTemplate,
} from '../../../core/actions/emailTemplateActions'
import { hideModal } from '../../../core/_actions/modalActions'
import packetsendactions from '../../../core/actions/packets/packetsendactions'

import Warning from '../../../core/assets/images/icons/warning'
import IconEmail from '../../../core/assets/images/icons/icon-email'
import VehicleIcon from '../../../core/assets/images/icons/vehicle-icon'
import VehicleMultipleIcon from '../../../core/assets/images/icons/vehicle-multiple-icon'

import '../styles/emailTemplates.scss'

function AddEmailTemplate(props) {
  const {
    hideModalConnect,
    addTemplateConnect,
    changeTemplateTypeConnect,
    token,
    currentTemplateType,
    propsTemplateType,
    companyId,
    storeIds,
    defaultStoreId,
    authUserId,
    testVehicleId,
    darkModeEnabled,
  } = props
  const [textAreaValue, setTextAreaValue] = useState('')
  const [richTextValue, setRichTextValue] = useState(RichTextEditor.createEmptyValue())
  const [inputFieldValue, setInputFieldValue] = useState('')
  const [templateType, settemplateType] = useState(null)
  const [isSingleVehicle, setSingleVehicle] = useState('Single')
  const initialStoreIdValue = storeIds.length > 0 ? storeIds[0].company_store_id : defaultStoreId
  const [currentStoreId, setCurrentStoreId] = useState(initialStoreIdValue)
  const [lastCursorPos, setLastCursorPos] = useState(0)
  const [isKeywordClicked, setIsKeywordClicked] = useState(false)
  const keywordSvgClass = isKeywordClicked ? 'keyword-button-clicked' : 'keyword-button'
  const companyStoreDisabledBtn =
    templateType === null ||
    (currentTemplateType === 'Store' && currentStoreId === -1) ||
    isSingleVehicle === '' ||
    inputFieldValue.length === 0 ||
    textAreaValue.length === 0
  const userDisabledBtn =
    propsTemplateType === null ||
    propsTemplateType === undefined ||
    isSingleVehicle === '' ||
    inputFieldValue.length === 0 ||
    textAreaValue.length === 0
  const [emailPreviewVisisble, setEmailPreviewVisisble] = useState(false)
  const previewButtonClass = emailPreviewVisisble ? 'preview-button-clicked' : 'preview-button'
  const previewButtonText = emailPreviewVisisble ? 'Hide Email Preview' : 'Show Email Preview'
  const [fetchedMessage, setFetchedMessage] = useState('')
  const htmlMessage = RichTextEditor.createValueFromString(
    textAreaValue.replace(/\n/g, '<br />'),
    'html',
  )
  const modifiedMessage = fetchedMessage.replace('{{message}}', richTextValue.toString('html'))
  const templateTextPlaceholder = `Hello Jane,

Here is the information you requested about the {{ vehicle }}.
Please let me know if you need anything else.

Thank you,
{{ user }}`

  const handleRichTextField = value => {
    setRichTextValue(value)
    setTextAreaValue(value.toString('html'))
  }

  const handleTemplateChange = newTemplateType => {
    changeTemplateTypeConnect(newTemplateType)
    settemplateType(newTemplateType)
  }

  const fetchEmailPreview = () => {
    const vehicle_ids = []
    vehicle_ids.push(testVehicleId)
    packetsendactions
      .getEmailPreview(vehicle_ids, token)
      .then(res => {
        if (res) {
          setFetchedMessage(res)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleEmailPreview = () => {
    if (emailPreviewVisisble === false) {
      fetchEmailPreview()
      setEmailPreviewVisisble(true)
    } else {
      setEmailPreviewVisisble(false)
    }
  }

  const handleKeywordInput = (keyword: string) => {
    const selectionState = richTextValue.getEditorState().getSelection()
    const currentContent = richTextValue.getEditorState().getCurrentContent()
    const blockMap = {}

    const currentAnchorKey = selectionState.getAnchorKey()
    const currentCursorPosition = selectionState.getEndOffset()
    const currentContentBlock = currentContent.getBlockForKey(currentAnchorKey).getText()

    currentContent.getBlockMap().map(contentBlock => {
      blockMap[contentBlock.getKey()] = contentBlock.getText()
    })

    const slicedText = currentContentBlock.slice(currentCursorPosition).split(' ')
    slicedText.unshift(keyword)
    slicedText.unshift(currentContentBlock.slice(0, currentCursorPosition))
    slicedText[0].length === 0 ? slicedText.splice(0, 1) : null
    const editedText = slicedText.join(' ')

    blockMap[currentAnchorKey] = editedText

    const outputString = Object.values(blockMap)
      .map(contentBlock => {
        return '<p>' + contentBlock + '<p/>'
      })
      .join('\n')

    setRichTextValue(RichTextEditor.createValueFromString(outputString, 'html'))

    // reset the dropdown
    document.getElementById('rte-custom-select').selectedIndex = 0
  }

  const saveChanges = () => {
    const isSingleVehicleBoolean = isSingleVehicle === 'Single' ? true : false

    if (propsTemplateType) {
      const newTemplate = {
        name: inputFieldValue,
        user: authUserId,
        message: richTextValue.toString('html'),
        is_single_vehicle: isSingleVehicleBoolean,
        is_mutliple_vehicle: !isSingleVehicleBoolean,
      }
      createEmailTemplate(token, newTemplate)
        .then(res => {
          console.log(res)
          addTemplateConnect(res, propsTemplateType)
          toast(`Successfully added ${res.name}`)
        })
        .catch(error => {
          console.error(error)
          toast(`Error: unable to add ${newTemplate.name}`)
        })
    } else {
      if (currentTemplateType === 'Company') {
        const newTemplate = {
          name: inputFieldValue,
          company: companyId,
          message: richTextValue.toString('html'),
          is_single_vehicle: isSingleVehicleBoolean,
          is_mutliple_vehicle: !isSingleVehicleBoolean,
        }

        createEmailTemplate(token, newTemplate)
          .then(res => {
            console.log(res)
            addTemplateConnect(res, currentTemplateType)
            toast(`Successfully added ${res.name}`)
          })
          .catch(error => {
            console.error(error)
            toast(`Error: unable to add ${newTemplate.name}`)
          })
      } else {
        const newTemplate = {
          name: inputFieldValue,
          store: currentStoreId,
          message: richTextValue.toString('html'),
          is_single_vehicle: isSingleVehicleBoolean,
          is_mutliple_vehicle: !isSingleVehicleBoolean,
        }
        createEmailTemplate(token, newTemplate)
          .then(res => {
            console.log(res)
            addTemplateConnect(res, currentTemplateType)
            toast(`Successfully added ${res.name}`)
          })
          .catch(error => {
            console.error(error)
            toast(`Error: unable to add ${newTemplate.name}`)
          })
      }
    }

    hideModalConnect()
  }

  const customrteSelectTag = () => {
    return (
      <select
        id="rte-custom-select"
        onChange={e => handleKeywordInput(e.target.value)}
        className="rte-custom-select"
      >
        <option disabled selected>
          Select a keyword
        </option>
        <option value={`{{user}}`}>User</option>
        <option value={`{{vehicle}}`}>Vehicle</option>
      </select>
    )
  }

  const rteCustomControls = () => {
    return [() => customrteSelectTag()]
  }

  return (
    <Modal>
      <div className="addEmailTemplate">
        <div className="template-options">
          {propsTemplateType ? (
            <div className="templateType-radio">
              <span className={darkModeEnabled ? 'radio-label-dark radio-label' : 'radio-label'}>
                Template Types
              </span>
              <span>{propsTemplateType}</span>
            </div>
          ) : (
            <div className="templateType-radio">
              <ToolTip
                onHover
                tip={
                  <React.Fragment>
                    This option allows you to specify whether this template
                    <br />
                    belongs to a store or company.
                    {currentTemplateType === 'Company' ? (
                      <React.Fragment>
                        <br />
                        <br />
                        The company is selected automatically.
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                }
              >
                <Warning className="tooltip-icon" />
              </ToolTip>
              <span className={darkModeEnabled ? 'radio-label radio-label-dark' : 'radio-label'}>
                Template Types
              </span>
              <input
                type="radio"
                name="templateType"
                onChange={e => handleTemplateChange(e.target.value)}
                value="Company"
              />
              <label htmlFor="templateType">Company</label>
              <input
                type="radio"
                name="templateType"
                onChange={e => handleTemplateChange(e.target.value)}
                value="Store"
              />
              <label htmlFor="templateType">Store</label>
            </div>
          )}

          {storeIds.length > 0 && currentTemplateType === 'Store' && (
            <div className="templateType-radio" id="store-name">
              <ToolTip
                onHover
                tip={
                  <React.Fragment>
                    This option allows you to specify to which particular
                    <br />
                    store this template belongs to.
                  </React.Fragment>
                }
              >
                <Warning className="tooltip-icon" />
              </ToolTip>
              <span className={darkModeEnabled ? 'radio-label radio-label-dark' : 'radio-label'}>
                Store Name
              </span>
              <label htmlFor="storeIds">Store ID</label>
              <select
                id="storeIds"
                value={currentStoreId}
                onChange={e => setCurrentStoreId(e.target.value)}
              >
                {storeIds.map(id => {
                  return (
                    <option key={id.company_store_id.toString()} value={id.company_store_id}>
                      {id.company_store_name}
                    </option>
                  )
                })}
              </select>
            </div>
          )}

          <div className="templateType-radio" id="vehicle-number">
            <ToolTip
              onHover
              tip={
                <React.Fragment>
                  This option allows you to specify whether the template
                  <br />
                  will be for single or multiple packet sends.
                </React.Fragment>
              }
            >
              <Warning className="tooltip-icon" />
            </ToolTip>
            <span className={darkModeEnabled ? 'radio-label radio-label-dark' : 'radio-label'}>
              Vehicle Number
            </span>
            <select onChange={e => setSingleVehicle(e.target.value)}>
              <option value="Single">Single</option>
              <option value="Multiple">Multiple</option>
            </select>
            <span className="vehicle-icon">
              {isSingleVehicle === 'Single' ? (
                <VehicleIcon />
              ) : (
                <VehicleMultipleIcon color="#3278c8" />
              )}
            </span>
          </div>
        </div>

        <div className={darkModeEnabled ? 'inputField inputField-dark' : 'inputField'}>
          <label htmlFor="Email Template Name">Email Template Name</label>
          <input
            name="Email Template Name"
            type="text"
            placeholder="Enter Template Name"
            value={inputFieldValue}
            onChange={e => setInputFieldValue(e.target.value)}
            autoFocus
          />
        </div>

        <div className="preview-button-container">
          <ToolTip
            onHover
            tip={
              <React.Fragment>
                This button allows you get a preview of your email.
                <br />
                This preview shows you how your email wil actually look
                <br />
                after sharing a packet through email.
              </React.Fragment>
            }
          >
            <div
              className="preview-button"
              data-clicked={emailPreviewVisisble ? 'true' : ''}
              onClick={() => handleEmailPreview()}
            >
              <IconEmail color={emailPreviewVisisble ? '#fefefe' : '#1cd760'} /> {previewButtonText}
            </div>
          </ToolTip>
        </div>

        {emailPreviewVisisble && (
          <div className="email-preview">
            <h3>Email Preview</h3>
            <div className="email-body" dangerouslySetInnerHTML={{ __html: modifiedMessage }} />
          </div>
        )}

        <div
          className="keyword-button"
          data-clicked={isKeywordClicked ? 'true' : ''}
          onClick={() => setIsKeywordClicked(!isKeywordClicked)}
        >
          <Warning />
          {isKeywordClicked ? 'Hide Keyword Info' : 'Show Keyword Info'}
        </div>

        <div className="keyword-disclaimer">
          {isKeywordClicked && (
            <React.Fragment>
              <p>
                You can add keywords to your template to make it more re-usable. Keywords allow you
                to inject variable data into your templates.
              </p>
              <p>
                Here is a list of keywords you can use:
                <li>{isSingleVehicle === 'Single' ? `{{ vehicle }}` : `{{ vehicle_count }}`}</li>
                <li>{`{{ user }}`}</li>
              </p>
            </React.Fragment>
          )}
        </div>

        <RichTextEditor
          id="richTextTemplateInput"
          value={richTextValue}
          className={darkModeEnabled ? 'richTextEditor-dark richTextEditor' : 'richTextEditor'}
          onChange={value => handleRichTextField(value)}
          placeholder={templateTextPlaceholder}
          customControls={rteCustomControls()}
        />

        <button
          className="submit"
          type="submit"
          onClick={() => saveChanges()}
          disabled={propsTemplateType ? userDisabledBtn : companyStoreDisabledBtn}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  currentTemplateType: state.emailTemplates.currentTemplateType,
  token: state.authentication.user.token,
  companyId: state.modal.props.companyId,
  storeIds: state.modal.props.storeIds,
  defaultStoreId: state.modal.props.defaultStoreId,
  propsTemplateType: state.modal.props.templateType,
  authUserId: state.authentication.user.user.profile.auth_user_id,
  testVehicleId:
    state.inventory.inventoryContent.results &&
    state.inventory.inventoryContent.results.length > 0 &&
    state.inventory.inventoryContent.results[0].vehicle_id,
  darkModeEnabled:
    state.authentication.user && state.authentication.user.user.profile.dark_mode_webapp,
})

const mapDispatchToProps = {
  hideModalConnect: hideModal,
  changeTemplateTypeConnect: changeTemplateType,
  addTemplateConnect: addTemplate,
  renderEmailTemplateConnect: renderEmailTemplate,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailTemplate)
