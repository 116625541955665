// general: 1/6 of the admin component - child of adminsettings - (3rd).

// @flow
import React, { Component } from 'react'
// node packages
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import LazyLoad from 'react-lazy-load'
import { CSVLink, CSVDownload } from 'react-csv'

// --- redux
import { connect } from 'react-redux'
// core files
// --- actions
import AdminActions from '../../../core/actions/users/adminactions'
// --- data
import AIPSeedsData from '../../../core/data/AIPSeedsData'
// redux
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import { ADMIN_TEAMMEMBER_MODAL, DELETE_MODAL } from '../../../core/_constants/modalTypes'
import { deleteModalAction } from '../../../core/_actions/packetstashActions'
// helpers
import { ReactTableOptionsStatic } from '../../helpers/reusables'
import LoadingSpinner from '../../helpers/loadingspinner'
import Helper from '../../../core/helpers/platformhelper'
import runtimeEnv from '../../../core/config/envConstants'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/adminsettings.scss'
// images
import IconTrash from '../../../core/assets/images/icons/icon-trash'
import IconAddTeam from '../../../core/assets/images/icons/icon-add-team'
import EditIcon from '../../../core/assets/images/icons/editicon'
import ProfileIcon from '../../../core/assets/images/icons/profileicon.png'

// global variable
let setTimeoutId

type Props = {
  userId: number,
  DJtoken: string, // token to send to get the data.
  storeId: number, // sent for the admin overview data.
  showModal: Function,
  deletemodal: boolean,
}

export class AdminTeamMembersComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storeId: this.props.storeId,

      teammemberAPIType: 'normal',
      entries: [], // team member table json.results, if returned results.length > 0
      // ^else, remains empty.
      pagePrevious: null, // team member table json.previous
      pageNext: '1', // team member table json.next
      count: 0, // team member table json.count
      pageNum: 0, // react-table's page number variable that either gets -- or ++ based on
      // ^this.state.count status
      search: '', // user search state
      loading: true, // react-table's option's loading value

      deletemodal: this.props.deletemodal,
      userRemoveId: null,

      isDesktop: true,
    }

    // input container onChange event that is stalled with a 'bind'.
    this._TeamMemberUpdateField = this.TeamMemberUpdateField.bind(this)
  }

  state: {
    storeId: number,
    teammemberAPIType: string,
    entries: Object,
    pagePrevious: string,
    pageNext: string,
    count: number,
    pageNum: number,
    search: string,
    loading: boolean,
    deletemodal: boolean,
    userRemoveId: number,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
    console.log('right here')
    if (this.mounted) {
      this.onMountSetDataSourceToLoading()
      const { storeId } = this.state
      if (storeId !== null && storeId !== undefined) {
        this.TeamMemberGetAPIData('normal', '1', '', storeId)
      }
    }
    this.setState({ isDesktop: window.innerWidth > 700 })
    window.addEventListener('resize', () => this.updateWindowDimensions())
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    const { storeId, deletemodal } = newProps

    if (storeId !== null && storeId !== undefined) {
      if (storeId !== this.props.storeId) {
        this.setState({ storeId })
        this.TeamMemberGetAPIData('normal', '1', '', storeId)
      }
    }

    if (deletemodal) {
      this.RemoveTeamMember(this.state.userRemoveId)
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  // - clear global timeout variable
  componentWillUnmount() {
    this.mounted = false

    clearTimeout(setTimeoutId)

    this.setState({
      storeId: null,
      teammemberAPIType: 'normal',
      entries: [],
      pagePrevious: null,
      pageNext: '1',
      count: 0,
      pageNum: 0,
      search: '',
      loading: true,
      deletemodal: false,
      userRemoveId: null,
    })

    window.removeEventListener('resize', () => this.updateWindowDimensions())
  }

  updateWindowDimensions() {
    this.setState({ isDesktop: window.innerWidth > 700 })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountSetDataSourceToLoading
  // - sets dummy data onmount into the table for row init.
  // - called once onmount
  onMountSetDataSourceToLoading() {
    const entries = AIPSeedsData.admin_team_members
    this.setState({ entries, loading: false })
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*MODAL*SETUP***********************
  // AdminTeamShowModals
  AdminTeamShowModals(userId: number, userEdit: boolean, userAdd: boolean) {
    this.props.showModal(ADMIN_TEAMMEMBER_MODAL, {
      title: 'Team Member',
      user_id: userId,
      store_id: this.state.storeId,
      user_edit: userEdit,
      user_add: userAdd,
      token: this.props.DJtoken,
      isAdmin: userId === this.props.userId,
    })
  }

  // RemoveTeamMemberModal
  RemoveTeamMemberModal(userRemoveId: number, removeItem: string) {
    if (!this.state.deletemodal) {
      this.setState({ userRemoveId })
      this.props.showModal(DELETE_MODAL, { removeItem })
    }
  }

  // RemoveTeamMember
  RemoveTeamMember(userRemoveId: number) {
    const { storeId } = this.state
    const { deleteModalAction } = this.props

    AdminActions.deleteAdminMember(userRemoveId, this.props.DJtoken)
      .then(() => {
        this.TeamMemberGetAPIData('normal', '1', '', storeId)
        //deleteModalAction(false)
      })
      .then(() => {
        deleteModalAction(false)
      })
  }
  // ***********************LETS*CALL*MODAL*SETUP***********************

  // ***********************LETS*CALL*API***********************
  // TeamMemberGetAPIData
  // - sends manipulated states to the API as the correct request params to get the
  // returned team member data;
  TeamMemberGetAPIData(
    teammemberAPIType: string,
    pageDirection: string,
    search: string,
    storeId: number,
  ) {
    this.setState({ loading: true })

    if (teammemberAPIType === 'normal') {
      AdminActions.getAdminMembersList(pageDirection, this.props.DJtoken, null, storeId)
        .then(json => {
          if (json !== null && json !== undefined) {
            if (json.results && json.results.length > 0) {
              this.TeamMemberConfigureStates(json.results, pageDirection, json.count)
              this.setState({
                pagePrevious: json.previous !== null ? json.previous.split('store/')[1] : null,
                pageNext: json.next !== null ? json.next.split('store/')[1] : null,
              })
            } else {
              this.TeamMemberConfigureStates([AIPSeedsData.admin_team_members], '1', 0)
              this.setState({ pagePrevious: null, pageNext: '1' })
            }
          } else {
            this.TeamMemberConfigureStatesFailed()
          }
        })
        .catch(error => {
          this.TeamMemberConfigureStatesFailed()
          console.log(error.toString())
        })
    } else if (teammemberAPIType === 'search') {
      AdminActions.getAdminMembersList(pageDirection, this.props.DJtoken, search, storeId)
        .then(json => {
          if (json !== null && json !== undefined) {
            if (json.results && json.results.length > 0) {
              this.TeamMemberConfigureStates(json.results, pageDirection, json.count)
              this.setState({
                pagePrevious: json.previous !== null ? json.previous.split('store/')[1] : null,
                pageNext: json.next !== null ? json.next.split('store/')[1] : null,
              })
            } else {
              this.TeamMemberConfigureStates([], '1', 0)
              this.setState({ pagePrevious: null, pageNext: '1' })
            }
          }
        })
        .catch(error => {
          this.TeamMemberConfigureStatesFailed()
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*API***********************

  // ***********************LETS*CONFIGURE*THE*API***********************
  // TeamMemberConfigureStates
  TeamMemberConfigureStates(json: Object, pageDirection: string, count: number) {
    this.setState({
      entries: json,
      pageNum: pageDirection === '1' ? 0 : this.state.pageNum,
      count,
      loading: false,
    })
  }

  // TeamMemberConfigureStatesFailed
  TeamMemberConfigureStatesFailed() {
    this.setState({ count: 0, loading: false })
  }
  // ***********************LETS*CONFIGURE*THE*API***********************

  // ***********************LETS*DO*FUNCTIONS***********************
  // PrevButton
  PrevButton(pagePrevBoolean: boolean) {
    // states
    const { teammemberAPIType, pagePrevious, search, pageNum, storeId } = this.state
    return (
      <button
        disabled={!pagePrevBoolean}
        onClick={() => {
          if (pagePrevBoolean) {
            this.TeamMemberGetAPIData(teammemberAPIType, pagePrevious, search, storeId)
            this.setState({ pageNum: pageNum - 1 })
          }
        }}
      >
        Back
      </button>
    )
  }

  // NextButton
  NextButton(pageNextBoolean: boolean) {
    // states
    const { teammemberAPIType, pageNext, search, pageNum, storeId } = this.state
    return (
      <button
        disabled={!pageNextBoolean}
        onClick={() => {
          if (pageNextBoolean) {
            this.TeamMemberGetAPIData(teammemberAPIType, pageNext, search, storeId)
            this.setState({ pageNum: pageNum + 1 })
          }
        }}
      >
        Next
      </button>
    )
  }

  // TeamMemberUpdateField
  // - updates the 'search' state on keystrokes.
  // - there is a timeout here for fast typers.
  TeamMemberUpdateField(stateName: string, text: string) {
    // clear global timeout variable
    clearTimeout(setTimeoutId)

    const textSearch = text.target.value

    const newState = {}
    newState[stateName] = textSearch
    this.setState(newState)

    if (text !== '' && text !== undefined) {
      this.setState({ teammemberAPIType: 'search' })
      setTimeoutId = setTimeout(() => {
        this.TeamMemberGetAPIData('search', '1', textSearch, this.state.storeId)
      }, 400)
    } else if (text === '') {
      this.setState({ teammemberAPIType: 'normal' })
      this.TeamMemberGetAPIData('normal', '1', '', this.state.storeId)
    }
  }
  defaultProfSrc(e) {
    e.target.src = ProfileIcon
  }

  // ***********************LETS*SETUP*COLUMNS***********************
  // TeamMemberColumnsContainer
  // renders the table and columns fully for the imported react-table
  TeamMemberColumnsContainer(columnsArray: Object) {
    const { role, staff } = this.props
    if (this.state.entries && this.state.entries.length > 0) {
      columnsArray.push({
        columns: [
          {
            Header: '',
            id: 'profile_image',
            accessor: d => {
              return (
                <div className="teammember-profile-image">
                  <LazyLoad debounce={false} throttle={125} threshold={200}>
                    <img
                      alt={`${d.first_name} ${d.last_name} Profile Image`}
                      src={d.profile_image_s3_path}
                      onError={this.defaultProfSrc}
                    />
                  </LazyLoad>
                </div>
              )
            },
            width: 50,
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                }}
              >
                Name
              </div>
            ),
            id: 'last_name',
            accessor: d => `${d.first_name} ${d.last_name}`,
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                }}
              >
                Email
              </div>
            ),
            id: 'user_email_address',
            accessor: d => d.user_email_address,
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                }}
              >
                Role
              </div>
            ),
            id: 'role_label',
            accessor: d => d.role_label,
            Cell: row => <div style={{ textAlign: 'left' }}>{row.value}</div>,
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                }}
              >
                Last Activity ({runtimeEnv.timezoneAbbr})
              </div>
            ),
            id: 'overall_last_login',
            accessor: d => {
              if (d.overall_last_login) {
                if (d.overall_last_login.includes('User')) {
                  return ['Never', '']
                }
                return Helper.dtFormat(
                  Helper.convertUTCandLocalize(d.overall_last_login),
                  'MMM do, yyyy-h:mm b zzz',
                ).split('-')
              }
              return ['Never', '']
            },
            className: 'team-members-logintime-cell',
            Cell: row => {
              return (
                <div
                  className={
                    'team-members-logintime-content' + (row.value[1] == '' ? ' single' : '')
                  }
                >
                  <span className="split-time">{row.value[0]}</span>
                  <span className="split-time">{row.value[1]}</span>
                </div>
              )
            },
          },
          {
            Header: () => (
              <div
                style={{
                  textAlign: 'left',
                }}
              >
                Edit
              </div>
            ),
            width: 40,
            id: 'edit_team_member',
            accessor: d => {
              if (d.legacy_user_id === null) {
                return (
                  <button
                    className="admin-edit-button"
                    onClick={() => this.AdminTeamShowModals(null, false, true)}
                  >
                    Add New
                  </button>
                )
              }
              return (
                <div
                  className="admin-edit-button"
                  onClick={() => this.AdminTeamShowModals(d.legacy_user_id, true, false)}
                >
                  <EditIcon />
                </div>
              )
            },
          },
        ],
      })
    }
    columnsArray.push({
      columns: [
        {
          Header: 'Remove',
          width: 70,
          id: 'remove_team_member',
          accessor: d => {
            return (
              <div
                disabled={d.legacy_user_id === null || d.legacy_user_id === this.props.userId}
                className="admin-remove-button"
                onClick={() =>
                  this.RemoveTeamMemberModal(d.legacy_user_id, `${d.first_name} ${d.last_name}`)
                }
              >
                <IconTrash />
              </div>
            )
          },
        },
      ],
    })
    return columnsArray
  }
  // ***********************LETS*SETUP*COLUMNS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // TeamTopContainer
  TeamTopContainer() {
    return (
      <div id="team-search-box" align="center">
        <div className="flexRow form-control" style={{ alignItems: 'center' }}>
          <label style={{ display: 'none' }} htmlFor="team-search-box-input">
            Team Search Input
          </label>
          <input
            id="team-search-box-input"
            type="text"
            name="search"
            placeholder="Search Team"
            style={{ width: '100%', border: 'none', outline: 0 }}
            onChange={text => this._TeamMemberUpdateField('search', text)}
            ref={text => {
              this._inputSearch = text
            }}
            value={this.state.search}
          />
          <button
            disabled={this.state.search === ''}
            onClick={() => {
              this.setState({ teammemberAPIType: 'normal', search: '' })

              setTimeoutId = setTimeout(() => {
                this.TeamMemberGetAPIData('normal', '1', '', this.state.storeId)
              }, 400)
            }}
          >
            X
          </button>
        </div>
      </div>
    )
  }

  // TeamMemberTableContainer
  // - renders the body of the table using the data returned from 'TeamMemberColumnsContainer'
  TeamMemberTableContainer() {
    // states
    const { entries, pageNext, pagePrevious, count, loading, pageNum, search } = this.state

    // options
    const ReactTableOptions = {
      className: '-striped -highlight',
      loading,
      sortable: false,
      loadingText: '',
    }
    if (loading) {
      return <LoadingSpinner loading wheel />
    }
    return (
      <ReactTable
        {...ReactTableOptions}
        {...ReactTableOptionsStatic}
        data={entries}
        resolveData={data => data.map(row => row)}
        columns={this.TeamMemberColumnsContainer([])}
        minRows={0}
        noDataText={search ? 'No matching team members found' : 'No team members found'}
        className="team_member-table"
        showPagination={true}
        page={pageNum}
        pages={count > 0 ? Math.ceil(count / 25).toFixed(0) : 1}
        PreviousComponent={() =>
          this.PrevButton(pagePrevious !== null && pagePrevious !== undefined)
        }
        NextComponent={() => this.NextButton(pageNext !== null && pageNext !== undefined)}
      />
    )
  }

  TeamMemberCard() {
    const { entries, loading, count, pagePrevious, pageNext, pageNum } = this.state
    const { darkMode } = this.props

    if (loading) {
      return <LoadingSpinner loading wheel />
    }

    const renderedCards = entries.map(entry => {
      let overall_last_login = ''
      if (entry.overall_last_login) {
        if (entry.overall_last_login.includes('User')) {
          overall_last_login = 'Never'
        } else {
          overall_last_login = Helper.dtFormat(
            Helper.convertUTCandLocalize(entry.overall_last_login),
            'MMM do, yyyy-h:mm b zzz',
          ).split('-')
        }
      }
      return (
        <div className="team-member-card">
          <div className="member-profile-and-actions">
            <div className="profile-picture">
              <LazyLoad debounce={false} throttle={125} threshold={200}>
                <img
                  alt={`${entry.first_name} ${entry.last_name} Profile Image`}
                  src={entry.profile_image_s3_path}
                  onError={this.defaultProfSrc}
                />
              </LazyLoad>
            </div>
            <div className="member-actions" data-mode={darkMode ? 'dark' : 'light'}>
              <button
                className="admin-edit-button"
                onClick={() => this.AdminTeamShowModals(entry.legacy_user_id, true, false)}
              >
                <EditIcon />
              </button>
              <button
                className="admin-remove-button"
                onClick={() =>
                  this.RemoveTeamMemberModal(
                    entry.legacy_user_id,
                    `${entry.first_name} ${entry.last_name}`,
                  )
                }
              >
                <IconTrash />
              </button>
            </div>
          </div>
          <div className="member-info">
            <div className="name">{`${entry.first_name} ${entry.last_name}`}</div>
            <div className="email">{entry.user_email_address}</div>
            <div className="role">{entry.role_label}</div>
            <div className="last-login">{overall_last_login}</div>
          </div>
        </div>
      )
    })

    return (
      <React.Fragment>
        <div className="member-pagination">
          {this.PrevButton(pagePrevious !== null && pagePrevious !== undefined)}
          <span>
            {pageNum + 1} of {count > 0 ? Math.ceil(count / 25).toFixed(0) : 1}
          </span>
          {this.NextButton(pageNext !== null && pageNext !== undefined)}
        </div>
        {renderedCards}
      </React.Fragment>
    )
  }

  exportTeamMember() {
    AdminActions.exportAdminMembersList(this.props.DJtoken, this.props.storeId)
      .then(res => {
        return res.blob()
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `teamexport.csv`)
        // 3. Append to html page
        document.body.appendChild(link)
        // 4. Force download
        link.click()
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props
  _TeamMemberUpdateField: Function // function
  _inputSearch: Function // ref

  render() {
    let { entries, isDesktop } = this.state
    let headers = [
      { label: 'First Name', key: 'first_name' },
      { label: 'Last Name', key: 'last_name' },
      { label: 'Email', key: 'email' },
      { label: 'CRM Email', key: 'crm_email' },
      { label: 'Role', key: 'role' },
      { label: 'Last Login', key: 'last_login' },
      { label: 'Phone Number', key: 'user_phone' },
      { label: 'Mobile Number', key: 'user_mobile' },
    ]
    let updatedTableInfo = entries.map(d => {
      let overall_last_login = ''
      if (d.overall_last_login) {
        if (d.overall_last_login.includes('User')) {
          overall_last_login = 'Never'
        } else {
          overall_last_login = Helper.dtFormat(
            Helper.convertUTCandLocalize(d.overall_last_login),
            'MMM do, yyyy-h:mm b zzz',
          ).split('-')
        }
      }
      return {
        first_name: `${d.first_name}`,
        last_name: `${d.last_name}`,
        email: d.user_email_address,
        role: d.role_label,
        last_login: overall_last_login,
        user_phone: d.user_phone,
        user_mobile: d.user_mobile,
      }
    })
    if (this.state.storeId !== null && this.state.storeId !== undefined) {
      return (
        <div align="center" className="white_box">
          <h1>Team Members</h1>
          {this.TeamTopContainer()}
          <div
            id="team-add-btn"
            onClick={() => this.AdminTeamShowModals(this.props.userId, false, true)}
          >
            <span>
              Add
              <br />
              User
            </span>
            <IconAddTeam />
          </div>
          <button
            id="team-add-btn"
            onClick={() => {
              this.exportTeamMember()
            }}
          >
            <span>
              Export
              <br />
              Team CSV
            </span>
          </button>
          <br />
          <br />
          {isDesktop ? (
            <div className="teammember-table">{this.TeamMemberTableContainer()}</div>
          ) : (
            this.TeamMemberCard()
          )}
        </div>
      )
    }

    return <LoadingSpinner loading wheel />
  }
}

const mapStateToProps = state => ({
  deletemodal: state.packetstash.deletemodal,
  staff: state.authentication.user.user.is_staff,
  role: state.authentication.user.user.profile.legacy_user.role_id,
  darkMode: state.authentication.user.user.profile.dark_mode_webapp,
})
const AdminTeamMembers = AdminTeamMembersComponent
export default connect(mapStateToProps, {
  hideModal,
  showModal,
  deleteModalAction,
})(AdminTeamMembers)
