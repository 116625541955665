import React from 'react'
import { connect } from 'react-redux'

function AppSkin(props) {
  const { darkModeEnabled } = props

  let skin_styles = {}

  if (darkModeEnabled) {
    console.log('DARK MODE ENABLED!!!')
    skin_styles.background = `
              body .subnav-bar .subnav-content a.active, body .subnav-bar .subnav-content a.active::after{
                background-color: #242526
              }
              body .headerMainStyle{
                box-shadow: 0 4px 2px -2px rgba(100,100,100,.5);
              }
              body .headerMainStyle .overview-page-jump button{
                background-color: none;
              }
              body .headerMainStyle .headerUserButtonStyle {
                background-color: transparent;
              }
              body #add_franchise_mssg, body #add_email_mssg{
                background-color: #4d4d4d;
              }
              body .tos-modal-text{
                background-color: inherit;
              }
              body p{
                color: white;
              }
              body button{
                background-color: #1cd760;
              }
              body input::placeholder{
                color: white;
              }
              body #team-search-box .form-control{
                background: none;
              }
              body .packetSendContentContainerStyle{background: none}
              body {background: #333!important}
              body .car-img-wrapper .view-packet-overlay{background: rgb(50,50,50)}
              body input, body .ReactTable .rt-thead.-filters input{
                background-color: inherit;
                color: white;
                border-style: solid;
              }
              body select, body .ReactTable .-pagination select{
                background-color: inherit;
                color: white;
              }
              body select option, body .ReactTable .-pagination select option{
                background-color: #4d4d4d;
                color: white;
              }
              body .loader-overlay{
                background-color: inherit
              }
              body #reporting-box .Collapsible .Collapsible__trigger {
                background-color: #b3b3b3;
              }
              body #reporting-box .Collapsible {
                background-color: #4d4d4d;
              }
              body .customer-info-box .scheduled-report-container select {
                background-color: #4d4d4d;
              }
              body .scheduled-report-modal .scheduled-report-container .recipients-list::after {
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #4d4d4d 90%);
              }
              body .customer-info-box, body .customer-info-box p{
                background:none;
                color: white;
              }
              body .dashboardViewsBox{
                background: none;
                color: white;
              }
              body .dashboardViewsBox li:nth-child(2n + 1){
                background: none;
              }
              body .dashboardViewsBox li:nth-child(2n){
                background-color: rgba(0,0,0,.1);
              }
              body .text-box{
                background: none;
              }
              body .form-submission-button{
                background-color: #1cd760;
              }
              body .send-modal-options-message .email-preview-modal .preview-modal-content {
                background-color: #4d4d4d
              }
              body .send-modal-customers-name{
                color: black;
              }
              body .send-modal-customers-found{
                background-color: #4d4d4d35;
                backdrop-filter: blur(35px);
              }
              body .send-modal-customers-found div span{
                color: white;
              }
              body .customer-form-box, body .customer-form-box .form-contact-methods {
                background-color: #4d4d4d;
              }
              body .customer-form-box button{
                background-color: #1cd760;
              }
              body .stickerpull-long-message{
                background: rgba(0,0,0,.75);
              }
              body .tos-agreement{
                background: inherit;
              }
              body .arc-display-general-vehicle-avg-recon-container{
                background: rgba(0,0,0,.1);
              }
              body .arc-display-detailed-repairs .arc-display-detailed-repairs-display-item{
                background: rgba(0,0,0,.1);
              }
          `

    skin_styles.foreground = `
              body #root .dashboard-page {background: #4d4d4d}
              body #root .dashboard-page .dashboard-store-select select {background: #4d4d4d}
              body #root .dashboard-page .dashboard-table {background: #4d4d4d}
              body #root .dashboard-page .dashboard-table-selector-option.active {background: #4d4d4d}
              body #root .dashboard-page .dashboard-table-selector-option {background: #68696D}
              body #root .dashboard-page .stick-left {background: #333}
              body #root .dashboard-page .stick-left {color: #ffffff}
              body #root .dashboard-page .stick-left {border-bottom: none}
              body #root .dashboard-page .dashboard-table .dashboard-table-row .dashboard-table-cell-default.item-data:nth-child(2) {background: #333}
              body #root .dashboard-page .dashboard-table .dashboard-table-row .dashboard-table-cell-default.social-data:nth-child(2) {background: #333}

              body #root .inventory-page {background: #4d4d4d}
              body #root .inventory-page #optGroupSelector {background: #4d4d4d;}
              body #root .inventory-page .car-display .update-packet-button {background: #4d4d4d}
              body #root .inventory-page .refine-menu .Collapsible h1 {background: #4d4d4d}
              body #root .inventory-page .refine-menu .Collapsible .Collapsible__contentOuter {background: #4d4d4d}
              body #root .inventory-page .refine-menu .Collapsible .Collapsible__contentOuter .scrollable-wrapper:after {background-image: linear-gradient(#4d4d4d, black)}
              body #root .inventory-page .refine-menu .Collapsible .Collapsible__contentOuter .refine-item {background: #4d4d4d}
              body #root .inventory-page .sidebar .basket-container .basket-items .basket-item {background: #333}
              body #root .inventory-page .sidebar .basket-container .basket-control {background: #333}
              body #root .inventory-page .sidebar .basket-container .basket-remove-all {background: #333}
              body #root .inventory-page .sidebar .basket-container .basket-remove-all:hover {background: #f83030}
              body #root .inventory-page .sidebar .basket-container .basket-items .basket-item:hover {background: #808080}
              body #root .inventory-page .basket-info{
                background: #333;
              }
              body #root .inventory-page .update-packet-button svg{
                fill: white;
              }
              body #root .inventory-page .car-display .remove-packet-button{
                background: #1cd760;
                color: white !important;
                border: 2px solid #1cd760 ;

              }
              body input:checked + .slider{
                background-color: #1cd760;
              }
              body .vdp-price-history .price-history-styling .phb-c{
                fill: #1cd760;
              }
              .packetsend-tab-nav .packetsend-tab-btn {
                background-color: #1cd760;
                border-top: 1px solid #1cd760;
                border-left: 1px solid #1cd760;
                border-right: 1px solid #1cd760;
              }
              .activity-report-table-item{
                color: white;
              }
              .activity-report .activity-report-collapsible-button{
                color: white;
              }
              .finance-table .finance-table-header-row .finance-table-header{
                background-color: #333;
              }
              .finance-table-selectors .finance-table-selector-active{
                background-color: #333;
              }
              .finance-table-selectors .finance-table-selector:hover{
                background-color: #333;
              }
              .vehicle-timeline-service .vertical-timeline .sort-by, .vehicle-timeline-service .vertical-timeline .sort-by select{
                background-color: #1cd760;
              }
              .vehicle-timeline-service .currentTimePeriod select,.vehicle-timeline-service .customLegend button, .vehicle-timeline-service .customLegend button svg{
                color: #1cd760;
                border-color: #1cd760;
                fill:#1cd760;
              }
              .vehicle-timeline-service .currentTimePeriod select:hover{
                background-color: #1cd760;
              }
              .vehicle-timeline-service .customLegend button.selected{
                background-color: #1cd760;
              }
              .vehicle-timeline-service .customLegend button:hover{
                background-color: #1cd760;
              }
              .store-search-results .store-search-info-container .store-search-store-name p,.store-search-results .store-search-info-container .store-search-store-company,.store-search-results .store-search-info-container .store-search-company-name{
                color: white;
              }
              .store-search-results .store-search-info-container .search-id,.store-search-results .store-search-info-container .store-search-store-name p, .store-search-results .store-search-info-container .store-search-store-company, .store-search-results .store-search-info-container .store-search-company-name{
                color: white;
              }
              .store-search-results .store-search-row a{
                color: #1cd760;
                transition: all ease-in-out 100ms;
              }
              .store-search-results .store-search-row a:hover{
                text-decoration: underline;
                transform: scale(1.02);

              }
              .store-search-results .store-search-info-container .store-search-store-name .search-id{
                color: white;
              }
              body #root #packetSendStyle .packetSendMainStyle {background: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle .packetSendTopBarContainerStyle .packetSendTopBarInnerStyle {background: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle .packetSendContentContainerStyle .packetSendSideContainersStyle {background: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle .packetSendMiddleContainersStyle {background: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle .packetSendTopBarContainerStyle .packetSendTopBarLinkStyle {background: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle .update-packet-button {background: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle {background: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle .activeModule,body #root #packetSendStyle .packetSendRightButtonSubbox .active{background: #1cd760}

              body .send-modal-options-message .email-preview-modal .preview-modal-content button { background-color: #4d4d4d }
              body #root .previous-attachments-modal .previous-attachments { background-color: #4d4d4d }

              body #root .customersMainStyle .customer-table-box {background: #4d4d4d}
              body #root .customer-form-box .state-box label select {background: #4d4d4d}

              body #root .white_box_upload {background: #4d4d4d}
              body #root .white_box_upload .upload-bullet .upload-dot {background: white}
              body #root .white_box_upload .file-drop .file-drop-target .upload_area select {background: #4d4d4d}

              body #root .finance-table-container {background: #4d4d4d}
              body #root .finance-table-container select {background: #4d4d4d}

              body #root .report_box {background: #4d4d4d}
              body #root .report_box #custom-report-select {background: #4d4d4d}
              body #root .report_box .rt-tr-group:nth-child(odd) {background: #68696D}
              body #root .report_box .rt-tr-group .rt-tr.-odd .stick-left {background: #68696D}
              body #root .report_box .rt-tr-group .rt-tr.-even .stick-left {background: #4d4d4d}
              body #root .report_box .packet_content_table .rt-thead .rt-th:nth-child(2) {background: #4d4d4d}
              body #root .report_box .packet_content_table .rt-thead .rt-th:nth-child(4) {background: #4d4d4d}
              body #root .report_box .packet-status-legend {background: #4d4d4d}
              body #root .absent-cell {background: #1cd760; opacity: 1!important}
              body #root .report_box .activity-report-row:nth-child(odd) {background: #68696D!important}
              body #root .report_box .activity-report-row:nth-child(even) {background: #4d4d4d!important}
              body #root .report_box .activity-report-custom-container .activity-report-custom-generate-button:hover {background-color: #16AA4C}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-table .activity-report-table-item:hover {background-color: #4d4d4d}
              body #root .report_box .activity-report .Collapsible .activity-report-table-container .activity-report-table-item:hover {background-color: #4d4d4d}
              body #root .report_box .activity-report-tables .activity-report-table-container .activity-report-table .activity-report-table-item:hover {background-color: #4d4d4d}

              body #root .sticker_box {background: #4d4d4d}
              body #root .sticker_box .document-viewer-container .module-pdf .module-viewer-header {background: #333}

              body #root .sticker-pull-coverage-container .sticker-pull-coverage-table-graph-range-medium-coverage {background: #b3b3b3}
              body #root .sticker-pull-coverage-container .sticker-pull-coverage-table-graph-range-low-coverage {background: #eee}
              body #root .sticker-pull-coverage-container .sticker-pull-coverage-table-graph-range-no-coverage {background: #333}
              body #root .archived-packet hr {background: #808289}

              body #root .white_box {background: #4d4d4d}
              body #root .white_box hr {background: #828387}
              body #root .white_box table .list-item.chip {background: #68696D}

              body #root .teammember-table .team_member-table .rt-thead {background: #4d4d4d}

              body #root .loc-select-box select {background: #4d4d4d}

              body #root .account-settings-page {background: #4d4d4d}
              body #root .account-settings-page .input-with-button button {background: #1cd760}
              body #root .account-settings-page .input-with-button button:hover {background: #19c056}
              body #root .account-settings-page .shown-section {background: #68696D}

              body #root .modal-dialog-div {background: #4d4d4d}
              body #root .modal-dialog-div social-share-success-container {background-color: #4d4d4d}
              body #root .modal-dialog-div .send-modal-container .modal-send-packet-container .modal-schedule-packet-container .schedule-packet-send-box {background: #4d4d4d}
              body #root .modal-dialog-div .send-modal-container .modal-send-packet-container .modal-schedule-packet-container .arrow-down .inner-arrow {border-top: 20px solid #4d4d4d}
              body #root .modal-dialog-div .send-modal-container .send-modal-menu .covideo-section .add-covideo-button {background: #767676}
              body #root .modal-dialog-div .report-issue-container .print-simple-btn:hover {background-color: #eee}

              body #root .overview-container {background: #4d4d4d}

              body #root .page-content {background: #4d4d4d}

              body #root .headerDropdownSectionStyle .headerUserDropDownStyle {background: #4d4d4d!important}
              body #root .headerDropdownSectionStyle .headerUserDropDownStyle:hover,
              body #root .headerDropdownSectionStyle .headerLinkDropDownStyle:hover {background: #76777F!important}

              body .unsubscribe-main-content {background-color: #4d4d4d}
              body .dropdown-container .dropdown .label, body .dropdown-container .dropdown .data-expanded {background-color: #4d4d4d}
              body .dropdown-container .dropdown .data-expanded .data-row:hover {background-color: #333}

              body #root .dashboard-page .calendar-holder .react-calendar .react-calendar__month-view__days .react-calendar__tile {background-color: #4d4d4d}
              body #root .dashboard-page .calendar-holder .react-calendar .react-calendar__month-view__days .react-calendar__tile {border: 1px solid #808289}
              body #root .dashboard-page .calendar-holder .react-calendar .react-calendar__month-view__days .react-calendar__tile .scheduled-packet-holder .scheduled-packet {border: none}
              body #root .dashboard-page .calendar-holder .react-calendar .react-calendar__month-view__days .react-calendar__tile abbr {color: white}
              body #root .dashboard-page .calendar-holder .react-calendar .react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth {background-color: #333}
              body #root .scheduleDetailSection .scheduleDetail .RichTextEditor__root___2QXK- {background-color: #4d4d4d}
              body #root .scheduleDetailSection .scheduleDetail .ButtonGroup__root___3lEAn>div>button {background-color: white}
              body #root .scheduleDetailSection .scheduleDetail .Dropdown__root___3ALmx {background-color: white}
              body #root .scheduleDetailSection .scheduleDetail .Dropdown__root___3ALmx {color: black}
              body #root .scheduleDetailSection .scheduleDetail .scheduleDetailDrawer.open {box-shadow: none}
              body #root .ButtonGroup__root___3lEAn {background: #fdfdfd}

          `

    skin_styles.font_color = `
              body {color: white;}
              body .company-overview-header .title-store-title .title-id{color: #1cd760}
              body #root .white_box_upload .upload_area .doc-queue-item{color: black}
              body #root .packetSendSubTitleStyle span{ color: white}
              body #root .packetSendDescriptionStyle{ color: white; }
              body #root .main-section{ color: white; }
              body #root th{ color: black; }
              body #root .graph-header-container selected{color:#1cd760;}
              body #root .dashboard-page .clickable {color: #1cd760;}
              body #root .dashboard-page .dashboard-table-view-count {color: white;}
              body #root .dashboard-page .dashboard-table-selector-option.active {color: white}
              body #root .dashboard-page .dashboard-table .dashboard-table-row,body #root .dashboard-page .dashboard-table dashboard-table-cell-multi-row {color: white}
              body #root .dashboard-page .dashboard-table .dashboard-table-row .dashboard-table-row-cell.dashboard-table-cell-default a {color: #1cd760}
              body #root .dashboard-page .dashboard-table .dashboard-table-results-info {color: white}
              body #root .white_box_upload .upload_area{ color: white;}

              body #root .inventory-page {color: white}
              body #root .inventory-page #active-filter-label {color: white}
              body #root .inventory-page .car-display .update-packet-button {color: #1cd760}
              body #root .inventory-page .sidebar .show-location {color: white !important}
              body #root .inventory-page .sidebar .result-count {color: white !important}
              body #root .inventory-page .sidebar .basket-item-info p {color: #1cd760}
              body #root .inventory-page .sidebar .basket-item-info {color: white}

              body #root #packetSendStyle .packetSendMainStyle {color: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendTopBarContainerStyle .packetSendTopBarInnerStyle {color: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendContentContainerStyle .packetSendSideContainersStyle {color: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendMiddleContainersStyle .packetSendTitleStyle {color: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendMiddleContainersStyle .packetSendInfoContainerStyle {color: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendMiddleContainersStyle .packetSendInfoContainerStyle span {color: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendTopBarContainerStyle .packetSendTopBarLinkStyle {color: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetLeftSideStyle .packetSendButtonsStyle {color: white}
              body #root #packetSendStyle .packetSendMainStyle .update-packet-button {color: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .update-packet-button:hover {color: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle {color: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle:hover {color: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle #reportIssue:hover {color: white}

              body #root .previous-attachments-modal .previous-attachments tr th { color: white }
              body #root .send-modal .send-modal-menu .send-modal-options-message .send-modal-email-preview .email-body p {color: black}
              body #root .modal-dialog-div .email-body p {color: black}
              body #root .send-modal .send-modal-menu .send-modal-add-customer-btn-box button:hover {color: white}
              body #root .send-modal .send-modal-menu .send-modal-email-preview-button button:hover {color: white}
              body #root .send-modal .send-modal-menu .send-modal-attachments-add-item-container button:hover {color: white}
              body #root .send-modal .send-modal-menu .send-modal-messaging-info .send-modal-add-customer-btn-box button {color: #1cd760}
              body #root .send-modal .send-modal-menu .send-modal-messaging-info .send-modal-add-customer-btn-box button:hover {color: white}
              body #root .send-modal .send-modal-menu .send-modal-options-message .email-options button {color: #1cd760}
              body #root .send-modal .send-modal-menu .send-modal-options-message .email-options button:hover {color: white}
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-add-item-container button {color: #1cd760}
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-add-item-container button:hover {color: white}
              body #root .modal-dialog-div .send-modal-container .send-modal-module-type {color: #4d4d4d; background-color: #b3b3b3}
              body #root .modal-dialog-div .send-modal-container .send-modal-module-type:hover {background-color: #ccc}
              body #root .previous-attachments-modal .previous-attachments table .delete-attachment svg.icon-additem { fill: white }
              body #root .previous-attachments-modal .previous-attachments table .delete-attachment svg.icon-additem:hover { fill: red }
              body #root .send-modal-attachments .send-modal-attachments-list .send-modal-attachments-item {background-color: #333}
              body #root .send-modal-attachments .send-modal-attachments-list .send-modal-attachments-item {border-color: #333}


              .previous-attachments-modal .previous-attachments table .delete-attachment svg
              body #root .footer {color: white}

              body #root .customersMainStyle .customer-table-box {color: white}
              body #root .customersMainStyle .customer-table-box h1 {color: white}
              body #root .customersMainStyle .customer-table-box .customer-display {color: white}

              body #root .white_box_upload {color: white}
              body #root .white_box_upload .upload-bullet {color: white}
              body #root .white_box_upload .upload-blurb {color: white}
              body #root .white_box_upload .file-drop .doc-upload-attention-text {color: #1cd760}

              body #root .finance-table-container {color: white}

              body #root .report_box {color: white}
              body #root .report_box .rt-td.stick-left span a:hover {color: #ccc}
              body #root .report_box .packet_content_table .rt-thead .rt-th:nth-child(2) {color: white}
              body #root .report_box .packet_content_table .rt-thead .rt-th:nth-child(4) {color: white}
              body #root .report_box .report_table .rt-thead .rt-th.-cursor-pointer {color: white!important}
              body #root .report_box .report_table .rt-thead.-filters input {border-color: #808289}
              body #root .report_box .report_table .header-text {color: white}
              body #root .report_box .packet-status-legend {color: white}
              body #root .report_box .packet_content_table .present-cell {color: white; opacity: 1!important;}
              body #root .report_box .activity-report-paginator .paginator-current {color: white}
              body #root .report_box .activity-report-row .activity-report-html {color: #1cd760}
              body #root .report_box .activity-report-row .activity-report-pdf {color: #1cd760}
              body #root .report_box .activity-report-row .activity-report-gendate {color: white}
              body #root .report_box .activity-report .Collapsible .activity-report-collapsible-button {color: #1cd760}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-section p {color: #1cd760}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-section a div div {color: #eee}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-table .activity-report-table-item:hover .activity-report-table-item-title {color: #1cd760}
              body #root .report_box .activity-report .Collapsible .activity-report-table .activity-report-table-item:hover .activity-report-table-item-title  {color: #1cd760}
              body #root .report_box .activity-report .Collapsible .activity-report-table-container .activity-report-table-title {color: #eee}
              body #root .report_box .activity-report .Collapsible .activity-report-table-container .activity-report-table-item {color: #eee}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-table .custom-report-details div:nth-of-type(2) {font-weight: 700}
              body #root .report_box .activity-report-custom-container .activity-report-custom-generate-button:hover {color: #ffffff}

              body #root .sticker_box {color: white}
              body #root .sticker_box p {color: white}
              body #root .sticker_box .stickerpull-beta {color: #1cd760}
              body #root .sticker_box .pull-history-table .pull-table-header {color: #1cd760}
              body #root .sticker_box .pull-history-table th {color: #1cd760}

              body #root .archived-packet .archived-packet-vehicles h3 {color: white}

              body #root .loc-select-box .loc-select-header span {color: white}
              body #root .white_box {color: white}

              body #root .account-settings-page {color: white}
              body #root .account-settings-page .shown-section p {color: white}

              body #root .modal-dialog-div {color: white}

              body #root .overview-container {color: white}
              body #root .overview-container h1 {color: white}
              body #root .overview-container h2 {color: white}
              body #root .overview-container h3 {color: white}
              body #root .overview-container h4 {color: white}
              body #root .overview-container p {color: white}
              body #root .overview-container .text-container .inline-flex {color: white}

              body #root .page-content {color: white}

              body #root .headerDropdownSectionStyle .headerUserDropDownStyle span {color: white}

              body #root .Dropdown__root___3ALmx select {color: black}

              body #root .tos-agreement-header {color: inherit}
              body #root .tos-agreement-content {color: inherit}
              body #root .tos-agreement-content span {color: #1cd760}
              body #root .tos-agreement-controls span {color: inherit}
              body .rdrInputRange {color: #4d4d4d;}
              body .car-last-price-change span, body #root #packetSendStyle .packetSendMainStyle .packetSendMiddleContainersStyle .packetSendInfoContainerStyle .car-last-price-change span {color: #1cd760;}

          `

    skin_styles.svg_style = `
              body #root .dashboard-main .dashboard-page .dashboard-search-bar img {filter: invert(1) brightness(2) opacity(0.8);}

              body #root .inventory-page #active-filter-label svg {fill: white}
              body #root .inventory-page .car-right-section .price-history-vlp .price-history-styling .phb-c {fill: #1cd760}
              body #root .inventory-page .car-right-section .price-history-vlp .price-history-styling .phb-b {stroke: #1cd760}
              body #root .inventory-page .car-right-section .price-history-vlp .price-history-styling .phb-delta {fill: #1cd760}
              body #root .packetSendRightButtonSubbox
              body #root #packetSendStyle .packetSendMainStyle .price-history-styling .phb-c {fill: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .price-history-styling .phb-b {stroke: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .price-history-styling .phb-delta {fill: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetSendTopBarContainerStyle .packetSendTopBarLinkStyle svg {fill: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .update-packet-button svg {fill: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .update-packet-button:hover svg {fill: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle svg {fill: white}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle:hover svg {fill: #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle#reportIssue svg path {fill: #4d4d4d}
              body #root #packetSendStyle .packetSendMainStyle #reportIssue svg .a {stroke: #f83030!important}
              body #root #packetSendStyle .packetSendMainStyle #reportIssue svg .b {fill: #f83030!important}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle#reportIssue:hover svg path {fill: #f83030}
              body #root #packetSendStyle .packetSendMainStyle #reportIssue:hover svg .a {stroke: white!important}
              body #root #packetSendStyle .packetSendMainStyle #reportIssue:hover svg .b {fill: white!important}

              body .send-modal-options-message .send-modal-email-preview .expand-icon-container button svg { fill: white }
              body #root .send-modal .send-modal-menu .send-modal-email-preview-button .send-modal-create-customer-btn {color: #1cd760}
              body #root .send-modal .send-modal-menu .send-modal-email-preview-button .send-modal-create-customer-btn:hover {color: white}
              body #root .send-modal .send-modal-menu .send-modal-email-preview-button .send-modal-create-customer-btn .icon-additem {fill: #1cd760}
              body .send-modal-options-message .email-preview-modal .preview-modal-content button svg { fill: white }
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-add-item-container .send-modal-attachments-add-item {color: #1cd760}
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-add-item-container .send-modal-attachments-add-item:hover {color: white}
              body #root .send-modal .send-modal-menu .send-modal-add-customer-btn-box .send-modal-create-customer-btn:hover .icon-additem {fill: white}
              body #root .send-modal .send-modal-menu .send-modal-email-preview-button .send-modal-create-customer-btn:hover .icon-additem {fill: white}
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-add-item-container .send-modal-attachments-add-item:hover svg path:nth-of-type(2) {fill: white!important}
              body #root .send-modal .send-modal-menu .send-modal-messaging-info .send-modal-add-customer-btn-box .send-modal-create-customer-btn svg {fill: #1cd760}
              body #root .send-modal .send-modal-menu .send-modal-options-message .email-options button svg {fill: #1cd760}
              body #root .send-modal .send-modal-menu .send-modal-options-message .email-options button svg:hover {fill: white}
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-add-item-container .send-modal-attachments-add-item svg path:nth-of-type(2) {fill: #1cd760 !important}
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-list-empty svg path:nth-of-type(2) {fill: #1cd760 !important}
              body #root .send-modal .send-modal-menu .send-modal-attachments .send-modal-attachments-list-empty svg:hover path:nth-of-type(2) {fill: white !important}

              body #root .customersMainStyle .customer-table-box .customer-display {fill: white}
              body #root .customersMainStyle .customer-table-box .customer-display:hover .edit-icon svg{fill: #1cd760}
              body #root .customersMainStyle .customer-table-box .customer-display .edit-icon svg:hover {fill: #1cd760}

              body #root .report_box .packet-status-legend .packet-legend-icon {fill: white!important}

              body #root .loc-select-box .loc-select-header svg {fill: white}
              body #root .white_box table .list-item.chip .list-item-actions svg {fill: white}

              body #root .sticker_box .stickerpull-form .cancel-icon svg {fill: white}
              body #root .sticker_box .stickerpull-form .cancel-icon:hover svg {fill: #f83030}

              body #root .teammember-table .rt-tbody svg {fill: #1cd760}

              body #root .overview-container .company-overview-header .inline-flex .small-icon .icon-map-location path {fill: white}
              body #root .overview-container .features-container-container .features-container .features-icons .featuresIcon svg path {fill: white}

              body #root .report_box .staff-tools .file-drop .bulk-user-upload .bulk-user-upload-button svg path:nth-child(2){fill: white}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-section a svg {fill: #eee}
              body #root .report_box .activity-report .Collapsible .activity-report-table-container .activity-report-table-item svg {fill: #eee}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-table .activity-report-table-item:hover .activity-report-table-item-icon-toggle svg {fill: #1cd760}
              body #root .report_box .activity-report .Collapsible .activity-report-table .activity-report-table-item:hover .activity-report-table-item-icon-toggle svg {fill: #1cd760}
              body #reporting-box .Collapsible .Collapsible__trigger svg {fill: white}

              body #root .headerDropdownSectionStyle .dropDownTextStyle svg {fill: white}
              body #root .headerDropdownSectionStyle .help-center svg .b {fill: #4d4d4d}

              body .dropdown-container .dropdown .label, body .dropdown-container .dropdown .icon svg { fill: white }
              body .dropdown-container .dropdown .label, body .dropdown-container .dropdown .icon-expanded svg { fill: white }
          `

    skin_styles.border = `
              body #root .dashboard-page {border: 1px solid #808289}
              body #root .dashboard-page .dashboard-store-select select {border: 2px solid #808289}

              body #root .inventory-page {border: 1px solid #808289}
              body #root .inventory-page #optGroupSelector {border: 2px solid #808289;}
              body #root .inventory-page .main {border-left: 1px solid #808289}
              body #root .inventory-page .main .car-display div[class^='car-container-'] {border-bottom: 1px solid #808289}
              body #root .inventory-page .main .page-controls .sort-by {border-bottom: 1px solid #808289}

              body #root #packetSendStyle .packetSendMainStyle {border: 1px solid #808289}
              body #root #packetSendStyle .packetSendMainStyle .packetSendTopBarContainerStyle {border-bottom: 1px solid #808289}
              body #root #packetSendStyle .packetSendMainStyle .packetSendMiddleContainersStyle {border-left: 1px solid #808289; border-right: 1px solid #808289}
              body #root #packetSendStyle .packetSendMainStyle .packetSendTopBarContainerStyle .packetSendTopBarLinkStyle {border: 2px solid #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetLeftSideStyle .packetSendButtonsStyle {border: 2px solid #808289}
              body #root #packetSendStyle .packetSendMainStyle .packetLeftSideStyle .packetSendButtonsStyle.activeModule {border: 2px solid #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .update-packet-button {border: 2px solid #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .update-packet-button:hover {border: 2px solid #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle {border: 2px solid #808289}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle:hover {border: 2px solid #1cd760}
              body #root #packetSendStyle .packetSendMainStyle .packetSendButtonsStyle#reportIssue:hover {border: 2px solid #f83030}

              body #root .customersMainStyle {border: none}
              body #root .customersMainStyle .customer-table-box {border: 1px solid #808289}

              body #root .white_box_upload {border: 1px solid #808289}
              body #root .white_box_upload .file-drop .doc-upload-attention {border: 2px solid #eeb21e}
              body #root .white_box_upload .file-drop .doc-upload-attention:focus {outline: 2px solid #1cd760}

              body #root .finance-table-container {border: 1px solid #b3b3b3}

              body #root .report_box {border: 1px solid #b3b3b3}
              body #root .report_box #custom-report-select {border: 2px solid #808289}
              body #root .report_box .rt-tr-group {border: 1px solid #68696D}
              body #root .report_box .packet-status-legend {border: 1px solid #68696D}
              body #root .absent-cell {border: 0.5px dashed #1cd760}
              body #root .report_box .activity-report-paginator .paginator-current {border: 2px solid #828387}
              body #root .report_box .staff-tools .file-drop .bulk-user-upload {border: 2px dashed #808289}
              body #root .report_box .activity-report .Collapsible .activity-report-collapsible-button {border-bottom: 2px solid #1cd760;}
              body #root .report_box .activity-report-custom-container .activity-report-custom-generate-button:hover {border-color: #16AA4C}

              body #root .sticker_box {border: 1px solid #b3b3b3}
              body #root .sticker_box .pull-history-container {border-top: 1px solid #b3b3b3}
              body #root .sticker_box .pull-history-table tr {border-bottom: 1px solid #b3b3b3}
              body #root .sticker_box .pull-history-table th {border-left: none}

              body #root .archived-packet .archived-packet-search-button {border: 1px solid #eee, border-left: none}
              body #root .archived-packet div[class^='car-container-'] {border-bottom: 1px solid #b3b3b3}

              body #root .white_box {border: 1px solid #b3b3b3}

              body #root .teammember-table .team_member-table .rt-thead.-header {border-bottom: 1px solid #828387}

              body #root .account-settings-page .shown-section {border: 1px solid #b3b3b3}

              body #root .modal-dialog-div {border: 1px solid #b3b3b3}

              body #root .overview-container {border: 1px solid #808289}

              body #root .page-content {border: 1px solid #808289}

              body #root .headerDropdownSectionStyle {border: 1px solid #808289}

              body #root .tos-agreement {border: 2px solid #4d4d4da7}

          `

    skin_styles.padding = `
              body #root .customersMainStyle {padding-top: 5px}
              body #root .report_box .activity-report .Collapsible .activity-report-custom-section:nth-child(2)::after {background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0),
                #4d4d4d 84%
              );}
          `
  } else {
    let skinStyleString = ''
    Object.keys(skin_styles).map(style => {
      skinStyleString += skin_styles[style]
    })

    return (
      <React.Fragment>
        {props.children}
        <style>{skinStyleString}</style>
      </React.Fragment>
    )
  }
  if (Object.keys) {
    let skinStyleString = ''
    Object.keys(skin_styles).map(style => {
      skinStyleString += skin_styles[style]
    })

    return (
      <React.Fragment>
        {props.children}
        <style>{skinStyleString}</style>
      </React.Fragment>
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

const mapStateToProps = state => ({
  darkModeEnabled:
    state.authentication.user && state.authentication.user.user.profile.dark_mode_webapp,
})

export default connect(mapStateToProps)(AppSkin)
