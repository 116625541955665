import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import Calendar from 'react-calendar'
import { format, subHours } from 'date-fns'

import StoreSelect from '../tools/components/storeselect'
import DashboardRow from './components/dashboardrow'
import DashboardRowSocial from './components/dashboardrowsocial'

import Paginator from '../helpers/paginator'
import LoadingSpinner from '../helpers/loadingspinner'

import CancelPNG from '../../core/assets/images/icons/cancel_black.png'
import SearchPNG from '../../core/assets/images/icons/search.png'
import SearchIcon from '../../core/assets/images/icons/search-icon.js'
import CancelIcon from '../../core/assets/images/icons/cancel-icon.js'
import IconEmail from '../../core/assets/images/icons/icon-email.js'

import runtimeEnv from '../../core/config/envConstants'

import './styles/dashboard.scss'

import { showModal, hideModal } from '../../core/_actions/modalActions'
import {
  DASHBOARD_CUSTOMER_MODAL,
  DASHBOARD_VIEWS_MODAL,
  DASHBOARD_SENT_MODAL,
  SCHEDULED_PACKET_MODAL,
} from '../../core/_constants/modalTypes'
import {
  getDashboardResults,
  getDashboardSocialResults,
  getDashboardPacketSource,
} from '../../core/actions/features/dashboardactions'

import { getScheduledPackets } from '../../core/actions/sendpacketactions'

import { stashScheduledPackets } from '../../core/_actions/packetstashActions'
import EyeClosedIcon from '../../core/assets/images/icons/eye-closed_icon'
import CovideoIcon from '../../core/assets/images/icons/covideo-icon'
import RefreshIcon from '../../core/assets/images/icons/refresh-icon'
import ToolTip from '../helpers/tooltip'

//last_viewed_date is a specific feature request for the viewed column
let defaultColumnTypes = [
  {
    name: 'Team Member',
    type: 'team_member',
    icon: <React.Fragment />,
  },
  {
    name: 'Customer',
    type: 'customer_id',
    icon: <React.Fragment />,
  },
  {
    name: 'Stock #',
    type: 'stock_number',
    icon: <React.Fragment />,
  },
  {
    name: 'Vehicle',
    type: 'vehicle_id',
    icon: <React.Fragment />,
  },
  {
    name: `Sent (${runtimeEnv.timezoneAbbr})`,
    type: 'sent_date',
    icon: <React.Fragment />,
  },
  {
    name: 'Views',
    type: 'last_viewed_date',
    icon: <React.Fragment />,
  },
  {
    name: 'Type',
    type: 'packet_type',
    types: [
      {
        source_id: -1,
        label: 'packet_type',
        client_display_label: 'All Types',
        type: 'all-share',
      },
    ],
    selectedId: -1,
    icon: <React.Fragment />,
  },
  {
    name: 'Insights',
    type: null,
    icon: <React.Fragment />,
  },
]
const socialColumnTypes = [
  {
    name: 'Team Member',
    type: 'team_member',
    icon: <React.Fragment />,
  },
  {
    name: 'Social Platform',
    type: 'social_network_name',
    icon: <React.Fragment />,
  },
  {
    name: 'Vehicle',
    type: 'vehicle_id',
    icon: <React.Fragment />,
  },
  {
    name: `Sent (${runtimeEnv.timezoneAbbr})`,
    type: 'sent_date',
    icon: <React.Fragment />,
  },
  {
    name: 'Views',
    type: 'view_count',
    icon: <React.Fragment />,
  },
]

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      query: '',
      activeQuery: '',
      selectedTable: 0,
      sortDir: 'desc',
      sortBy: 5,
      defaultSortBy: 4,
      itemsPerPage: 15,
      page: 1,
      numPages: 1,
      count: 0,
      results: null,
      columnTypes: defaultColumnTypes,
      scheduledPacketTable: false,
      calendarValue: new Date(),
      scheduledPackets: [],
      tableTypes: [
        {
          name: 'Viewed',
          type: 'viewed',
          icon: <React.Fragment />,
          defaultSortBy: 5,
        },
        {
          name: 'Hot List',
          type: 'hot_list',
          icon: <React.Fragment />,
          defaultSortBy: 5,
        },
        {
          name: 'User Shares',
          type: 'user_shares',
          icon: <React.Fragment />,
          defaultSortBy: 4,
        },
        {
          name: 'Price Changes',
          type: 'price_changes',
          icon: <React.Fragment />,
          defaultSortBy: 4,
        },
        {
          name: 'Social',
          type: null,
          icon: <React.Fragment />,
          //Overrides Default Functions to provide Fully Custom Table
          render: this.renderSocialTable.bind(this),
          get: this.getSocialResults.bind(this),
          defaultColumnTypes: socialColumnTypes,
          defaultSortBy: 4,
        },
        {
          name: 'All Sent',
          type: 'all',
          icon: <React.Fragment />,
          defaultSortBy: 4,
        },
        {
          name: 'Scheduled Packets',
          type: 'scheduled',
          icon: <React.Fragment />,
          defaultSortBy: 4,
        },
      ],
    }
  }

  componentDidMount() {
    const { defaultStore, activeStoreId } = this.props
    if (defaultStore && activeStoreId > -1) {
      this.updateResults()
    }
  }

  componentDidUpdate(prevProps) {
    const { activeStoreId } = this.props
    const { results, selectedTable } = this.state
    if (prevProps.activeStoreId !== activeStoreId) {
      this.getPacketSource()
      this.updateResults()
    }
  }

  getPacketSource() {
    const { token } = this.props
    getDashboardPacketSource({ token }).then(res => {
      if (res && res.length) {
        if (defaultColumnTypes[6].types.length < 2) {
          defaultColumnTypes[6].types = defaultColumnTypes[6].types.concat(res)
        }
      }
    })
  }

  getHasInsights() {
    const { activeStore } = this.props
    if (activeStore != undefined) {
      return activeStore.has_insights
    } else {
      return false
    }
  }
  getSocialResults() {
    const { columnTypes, page, sortDir, sortBy, query, itemsPerPage } = this.state
    const { token, storeId, userId, isAdmin, activeStoreId } = this.props
    console.log(userId)
    getDashboardSocialResults({
      token,
      page,
      itemsPerPage,
      sortDir,
      isAdmin,
      sortBy: columnTypes[sortBy].type,
      query,
      storeId: activeStoreId,
      userId,
    }).then(res => {
      if (res.results) {
        this.setState({
          results: res.results,
          count: res.count,
          loading: false,
        })
      }
    })
  }

  updateResults() {
    const {
      selectedTable,
      tableTypes,
      columnTypes,
      page,
      sortDir,
      sortBy,
      query,
      itemsPerPage,
    } = this.state
    const { token, storeId, userId, isAdmin, activeStoreId } = this.props

    this.setState({ loading: true, results: [] })
    if (tableTypes[selectedTable].get) {
      tableTypes[selectedTable].get()
    } else {
      if (selectedTable !== 6) {
        getDashboardResults({
          token,
          tableType: tableTypes[selectedTable].type,
          page,
          itemsPerPage,
          sortDir,
          sortBy: columnTypes[sortBy].type,
          query,
          storeId: activeStoreId,
          userId,
          isAdmin,
          packet_type_source_id: columnTypes[6].selectedId,
        }).then(res => {
          if (res && res.results) {
            this.setState({
              results: res.results,
              count: res.count,
              loading: false,
            })
          }
        })
      } else {
        getScheduledPackets(token).then(res => {
          if (res) {
            this.props.stashScheduledPacketsConnect(res)
            this.setState({
              loading: false,
            })
          }
        })
      }
    }
  }
  debounceUpdateResults = debounce(this.updateResults, 500)

  updateSelectedTable(newSelectedTable) {
    const { tableTypes, selectedTable, defaultSortBy } = this.state
    if (
      tableTypes[newSelectedTable].defaultSortBy === tableTypes[selectedTable].defaultSortBy &&
      !tableTypes[newSelectedTable].defaultColumnTypes
    ) {
      this.setState(
        {
          selectedTable: newSelectedTable,
          page: 1,
          columnTypes: defaultColumnTypes,
        },
        this.updateResults,
      )
    } else {
      let sortBy = defaultSortBy
      let columnTypes = defaultColumnTypes
      if (tableTypes[newSelectedTable].defaultSortBy) {
        sortBy = tableTypes[newSelectedTable].defaultSortBy
      }
      if (tableTypes[newSelectedTable].defaultColumnTypes) {
        columnTypes = tableTypes[newSelectedTable].defaultColumnTypes
      }
      this.setState(
        {
          selectedTable: newSelectedTable,
          columnTypes,
          sortBy,
          columnTypes,
          page: 1,
        },
        this.updateResults,
      )
    }
  }
  updateSearchQuery(query) {
    this.setState({ query }, this.debounceSearch)
  }
  debounceSearch = debounce(() => {
    this.setState({ page: 1 }, () => {
      const { query } = this.state
      this.setState({ activeQuery: query })
      this.updateResults()
    })
  }, 500)
  updatePage(page) {
    this.setState({ page }, this.updateResults)
  }
  renderTableSelector() {
    const { tableTypes, selectedTable } = this.state
    return (
      <div className="dashboard-table-selector dashboard-tab-nav">
        {tableTypes.map((type, i) => (
          <button
            key={type.type}
            title={`Navigate to ${type.name} tab`}
            className={`${
              i === selectedTable
                ? ' dashboard-table-selector-option active'
                : 'dashboard-table-selector-option'
            }`}
            onClick={() => {
              this.updateSelectedTable(i)
            }}
          >
            <span className={`dashboard-table-selector-name-${type.type}`}>
              {type.icon}
              {type.name}
            </span>
          </button>
        ))}
      </div>
    )
  }
  renderSearchBar() {
    const { query } = this.state
    return (
      <div className="dashboard-search-bar">
        <label style={{ display: 'none' }} htmlFor="searchDashboard">
          Search Dashboard
        </label>
        <input
          id="searchDashboard"
          placeholder="Search"
          type="text"
          value={query}
          onChange={e => {
            this.updateSearchQuery(e.target.value)
          }}
        />
        <img
          onClick={() => {
            if (query !== '') {
              this.updateSearchQuery('')
            }
          }}
          src={query !== '' ? CancelPNG : SearchPNG}
          className="dashboard-search-bar-img"
          alt="search-logo"
        />
      </div>
    )
  }
  renderStoreSelect() {
    return (
      <div className="dashboard-store-select">
        <label style={{ display: 'none' }} htmlFor="dashboard-store-select">
          Select Store for Dashboard
        </label>
        <StoreSelect />
      </div>
    )
  }
  renderTablePaginator() {
    const { page, itemsPerPage, count } = this.state
    return (
      <div className="dashboard-table-paginator">
        <Paginator
          page={page}
          updatePage={this.updatePage.bind(this)}
          numPages={Math.ceil(count / itemsPerPage)}
        />
        <ToolTip onHover tip={<React.Fragment>Update Table</React.Fragment>}>
          <button className="refresh-icon" onClick={() => this.updateResults()}>
            <RefreshIcon />
          </button>
        </ToolTip>
      </div>
    )
  }
  renderResultsInfo() {
    const { page, itemsPerPage, count, activeQuery } = this.state
    return (
      <div className="dashboard-table-results-info" style={{ animation: ` 1500ms fadeIn` }}>
        {`${
          count
            ? `Displaying ${(page - 1) * itemsPerPage + 1}-${
                page * itemsPerPage > count ? count : page * itemsPerPage
              } of`
            : ''
        } ${count} result${count > 1 || count === 0 ? 's' : ''} ${
          activeQuery.length ? `for ${activeQuery}` : ''
        }`}
      </div>
    )
  }
  renderTableHeader(hasInsights) {
    const { columnTypes, sortBy, sortDir } = this.state
    let insightsColumnTypes = hasInsights
      ? columnTypes
      : columnTypes.filter(function(obj) {
          return obj.name !== 'Insights'
        })
    return (
      <div className="dashboard-table-header dashboard-table-row">
        {insightsColumnTypes.map((columnType, i) =>
          columnType.types ? (
            <div
              key={columnType.type}
              className={`dashboard-table-row-cell dashboard-table-cell-default ${
                sortBy === i ? `dashboard-table-header-${sortDir}` : ''
              }`}
              onClick={() => {
                if (columnType.type) {
                  if (sortBy === i && sortDir === 'desc') {
                    this.setState({ sortDir: 'asc' }, this.updateResults)
                  } else {
                    this.setState({ sortBy: i, sortDir: 'desc' }, this.updateResults)
                  }
                }
              }}
            >
              <span>
                <label style={{ display: 'none' }} htmlFor="dashboard-type-select">
                  Select Type for Dashboard
                </label>
                <select
                  id="dashboard-type-select"
                  value={columnType.selectedId}
                  onClick={e => {
                    // if (i === sortBy) {
                    //   e.stopPropagation()
                    // }
                    e.stopPropagation()
                  }}
                  onChange={e => {
                    insightsColumnTypes[i].selectedId = parseInt(e.target.value)
                    this.setState({ defaultColumnTypes: insightsColumnTypes }, this.updateResults)
                  }}
                >
                  {columnType.types.map(type => (
                    <option value={type.source_id} key={type.source_id}>
                      {type.client_display_label}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          ) : (
            <div
              key={columnType.type}
              className={`dashboard-table-row-cell dashboard-table-cell-default ${
                sortBy === i ? `dashboard-table-header-${sortDir}` : ''
              }`}
              onClick={() => {
                if (columnType.type) {
                  if (sortBy === i && sortDir === 'desc') {
                    this.setState({ sortDir: 'asc' }, this.updateResults)
                  } else {
                    this.setState({ sortBy: i, sortDir: 'desc' }, this.updateResults)
                  }
                }
              }}
            >
              <span>
                {columnType.icon}
                {columnType.name}
              </span>
            </div>
          ),
        )}
      </div>
    )
  }
  renderTable() {
    const { selectedTable, tableTypes, columnTypes, results, loading } = this.state
    // hasInsights must work around getSelectedStore being called before store_access_list returned
    let hasInsights = this.getHasInsights()
    if (results) {
      if (tableTypes[selectedTable].render) {
        return tableTypes[selectedTable].render()
      }
      return (
        <div className="dashboard-table">
          {this.renderTablePaginator()}
          <div className="dashboard-table-content">
            {this.renderTableHeader(hasInsights)}
            {!loading ? (
              <div className="dashboard-table-rows" style={{ animation: ` 1500ms fadeIn` }}>
                {results.map((result, i) => (
                  <DashboardRow
                    key={result.packet_share_id}
                    share_uuid={result.packet_share_id}
                    info={result}
                    animationDelay={i * 15}
                    hasInsights={hasInsights}
                  />
                ))}
              </div>
            ) : (
              <LoadingSpinner loading wheel />
            )}
            {!results.length && !loading && (
              <div className="dashboard-table-no-results">No results found</div>
            )}
          </div>
          {this.renderResultsInfo()}
          <div id="dash-paginator-bottom">{this.renderTablePaginator()}</div>
        </div>
      )
    }
    return (
      <div className="dashboard-table">
        <div className="dashboard-table-content">
          <LoadingSpinner loading wheel />
        </div>
      </div>
    )
  }
  renderSocialTable() {
    const { selectedTable, tableTypes, columnTypes, results, loading } = this.state
    if (results) {
      return (
        <div className="dashboard-table">
          {this.renderTablePaginator()}
          <div className="dashboard-table-content social-table">
            {this.renderTableHeader()}
            {!loading ? (
              <div className="dashboard-table-rows" style={{ animation: ` 1500ms fadeIn` }}>
                {results.map((result, i) => (
                  <DashboardRowSocial
                    key={result.packet_share_id}
                    info={result}
                    animationDelay={i * 15}
                  />
                ))}
              </div>
            ) : (
              <LoadingSpinner loading wheel />
            )}
            {!results.length && !loading && (
              <div className="dashboard-table-no-results">No results found</div>
            )}
          </div>
          {this.renderResultsInfo()}
          <div id="dash-paginator-bottom">{this.renderTablePaginator()}</div>
        </div>
      )
    }
  }

  renderTileContent(activeStartDate, date, view) {
    const { scheduledPackets } = this.props
    const { token } = this.props

    let fullDate = format(date, 'yyyy-MM-dd')

    return scheduledPackets.map(packet => {
      const sendDate = packet.scheduled_time.split('T')[0]
      const customers = packet.customers
      const scheduledPacket = packet

      const customerString = []

      customers.map((customer, i) => {
        customerString.push(
          customer.first_name +
            ' ' +
            customer.last_name +
            (customers.length < 1 ? '' : customers.length !== i + 1 ? ', ' : '') +
            '\n',
        )
      })

      if (fullDate == sendDate) {
        if (customers.length > 1) {
          return (
            <div
              className="scheduled-packet-holder"
              onClick={() =>
                this.props.showModalConnect(SCHEDULED_PACKET_MODAL, {
                  packet: scheduledPacket,
                  token: token,
                })
              }
            >
              <div className="scheduled-packet">
                {packet.covideo_video_id && (
                  <div className="covideo-icon">
                    <CovideoIcon height="18px" />
                  </div>
                )}
                <p>{customerString}</p>
              </div>
            </div>
          )
        } else {
          return customers.map((customer, i) => {
            return (
              <div
                className="scheduled-packet-holder"
                onClick={() =>
                  this.props.showModalConnect(SCHEDULED_PACKET_MODAL, {
                    packet: scheduledPacket,
                    token: token,
                  })
                }
                key={i}
              >
                <IconEmail />
                <div className="scheduled-packet">
                  {packet.covideo_video_id && (
                    <div className="covideo-icon">
                      <CovideoIcon height="18px" />
                    </div>
                  )}
                  <p>
                    {customer.first_name} {customer.last_name}
                  </p>
                </div>
              </div>
            )
          })
        }
      }
    })
  }

  renderScheduledPacketCalendar() {
    const { calendarValue, loading } = this.state

    function formatDate(locale, date) {
      locale = locale.toString().split(' ')
      return locale[0]
    }

    console.log(this.props.scheduledPackets)

    return (
      <div className="calendar-holder">
        {loading ? (
          <LoadingSpinner loading wheel />
        ) : (
          <Calendar
            onChange={() => this.setState({ calendarValue: new Date() })}
            value={calendarValue}
            calendarType="US"
            formatShortWeekday={(locale, date) => formatDate(date, 'dd')}
            tileContent={({ activeStartDate, date, view }) =>
              this.renderTileContent(activeStartDate, date, view)
            }
            onClickDay={null}
            onClickMonth={null}
          />
        )}
      </div>
    )
  }

  render() {
    const { defaultStore, results, loading, stores, activeStore } = this.props
    const { selectedTable } = this.state
    if (defaultStore) {
      return (
        <div style={{ animation: ` 1500ms fadeIn` }} className="dashboard-main">
          <div className="dashboard-page">
            {selectedTable == 6 ? (
              <div>
                {this.renderTableSelector()}
                {this.renderStoreSelect()}
                {this.renderScheduledPacketCalendar()}
              </div>
            ) : (
              <div>
                {this.renderTableSelector()}
                <div className="select-search-cont">
                  {this.renderStoreSelect()}
                  {this.renderSearchBar()}
                </div>
                {this.renderTable()}
              </div>
            )}
          </div>
        </div>
      )
    }
    return <div />
  }
}
const mapStateToProps = state => ({
  defaultStore: state.authentication.user.user.profile.default_company_store,
  activeStore: state.tools.activeStore,
  activeStoreId: state.tools.activeStoreId,
  stores: state.tools.stores,
  isAdmin: state.authentication.userInfo.is_admin,
  token: state.userSettings.settings && state.userSettings.settings.dj_token,
  userId: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  scheduledPackets: state.packetstash.scheduled_packets,
})

export default connect(mapStateToProps, {
  showModalConnect: showModal,
  stashScheduledPacketsConnect: stashScheduledPackets,
})(Dashboard)
