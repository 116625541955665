import React, { useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import RichTextEditor from 'react-rte'

import Modal from '../../layouts/modal'
import ToolTip from '../../helpers/tooltip'

import { hideModal } from '../../../core/_actions/modalActions'
import packetsendactions from '../../../core/actions/packets/packetsendactions'

import IconEmail from '../../../core/assets/images/icons/icon-email'
import VehicleIcon from '../../../core/assets/images/icons/vehicle-icon'
import VehicleMultipleIcon from '../../../core/assets/images/icons/vehicle-multiple-icon'

import '../styles/emailTemplates.scss'

function ViewEmailTemplate(props) {
  const {
    hideModalConnect,
    selectedTemplate,
    token,
    storeName,
    templateType,
    testVehicleId,
    darkModeEnabled,
  } = props

  const [emailPreviewVisisble, setEmailPreviewVisisble] = useState(false)
  const previewButtonClass = emailPreviewVisisble ? 'preview-button-clicked' : 'preview-button'
  const previewButtonText = emailPreviewVisisble ? 'Hide Email Preview' : 'Show Email Preview'
  const [fetchedMessage, setFetchedMessage] = useState('')
  const modifiedMessage = fetchedMessage.replace(
    '{{message}}',
    selectedTemplate.message.toString('html'),
  )

  const fetchEmailPreview = () => {
    const vehicle_ids = []
    vehicle_ids.push(testVehicleId)
    packetsendactions
      .getEmailPreview(vehicle_ids, token)
      .then(res => {
        if (res) {
          setFetchedMessage(res)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleEmailPreview = () => {
    if (emailPreviewVisisble === false) {
      fetchEmailPreview()
      setEmailPreviewVisisble(true)
    } else {
      setEmailPreviewVisisble(false)
    }
  }

  return (
    <Modal>
      <div className="viewEmailTemplate addEmailTemplate">
        <div className="template-options">
          <div className="templateType-radio" id="ED-templateRadio">
            <span className={darkModeEnabled ? 'radio-label-dark radio-label' : 'radio-label'}>
              Template Type
            </span>
            <span>{templateType}</span>
          </div>

          {templateType === 'Store' && (
            <div className="templateType-radio">
              <span className={darkModeEnabled ? 'radio-label-dark radio-label' : 'radio-label'}>
                Store Name
              </span>
              <span>{storeName}</span>
            </div>
          )}

          <div className="templateType-radio">
            <span className={darkModeEnabled ? 'radio-label-dark radio-label' : 'radio-label'}>
              Vehicle Number
            </span>
            <span className="vehicle-icon">
              {selectedTemplate.is_single_vehicle === 'Single' ? (
                <span>
                  Single <VehicleIcon />
                </span>
              ) : (
                <span>
                  Multiple <VehicleMultipleIcon color="#1cd760" />
                </span>
              )}
            </span>
          </div>
        </div>

        <div className={darkModeEnabled ? 'inputField-dark inputField' : 'inputField'}>
          <label htmlFor="Email Template Name">Email Template Name</label>
          <input
            name="Email Template Name"
            type="text"
            placeholder="Enter Template Name"
            defaultValue={selectedTemplate.name}
            onChange={e => setInputFieldValue(e.target.value)}
            readOnly
          />
        </div>

        <div className="preview-button-container">
          <ToolTip
            onHover
            tip={
              <React.Fragment>
                This button allows you get a preview of your email.
                <br />
                This preview shows you how your email wil actually look
                <br />
                after sharing a packet through email.
              </React.Fragment>
            }
          >
            <div
              className="preview-button"
              data-clicked={emailPreviewVisisble ? 'true' : ''}
              onClick={() => handleEmailPreview()}
            >
              <IconEmail color={emailPreviewVisisble ? '#fefefe' : '#1cd760'} /> {previewButtonText}
            </div>
          </ToolTip>
        </div>

        {emailPreviewVisisble && (
          <div className="email-preview">
            <h3>Email Preview</h3>
            <div className="email-body" dangerouslySetInnerHTML={{ __html: modifiedMessage }} />
          </div>
        )}

        <RichTextEditor
          value={RichTextEditor.createValueFromString(selectedTemplate.message, 'html')}
          className="richTextEditor"
          onChange={value => setRichTextValue(value)}
          readOnly
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  selectedTemplate: state.modal.props.selectedTemplate,
  token: state.authentication.user.token,
  storeName: state.modal.props.storeName,
  templateType: state.modal.props.templateType,
  testVehicleId:
    state.inventory.inventoryContent.results &&
    state.inventory.inventoryContent.results.length > 0 &&
    state.inventory.inventoryContent.results[0].vehicle_id,
  darkModeEnabled:
    state.authentication.user && state.authentication.user.user.profile.dark_mode_webapp,
})

const mapDispatchToProps = {
  hideModalConnect: hideModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewEmailTemplate)
