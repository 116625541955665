import React, { useState, useEffect, useLayoutEffect } from 'react'
import { connect } from 'react-redux'

import {
  ADD_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  VIEW_EMAIL_TEMPLATE,
} from '../../../core/_constants/modalTypes'

import { showModal } from '../../../core/_actions/modalActions'
import {
  updateCompanyTemplateList,
  updateStoreTemplateList,
  getCompanyTemplates,
  getStoreTemplates,
  editTemplate,
  updateEmailTemplate,
} from '../../../core/actions/emailTemplateActions'

import ToolTip from '../../helpers/tooltip'
import LoadingSpinner from '../../helpers/loadingspinner'

import IconAddItem from '../../../core/assets/images/icons/icon-additem'
import EditIcon from '../../../core/assets/images/icons/editicon'
import Warning from '../../../core/assets/images/icons/warning'
import IconX from '../../../core/assets/images/icons/icon-x'
import EyeOpenIcon from '../../../core/assets/images/icons/eye-open_icon'

function EmailTemplates(props) {
  const {
    companyTemplates,
    storeTemplates,
    showModalConnect,
    updateCompanyTemplateListConnect,
    updateStoreTemplateListConnect,
    editTemplateConnect,
    token,
    companyId,
    companyName,
    user_id,
    defaultStoreId,
    defaultStoreName,
  } = props
  const [companySearchValue, setCompanySearchValue] = useState('')
  const [storeSearchValue, setStoreSearchValue] = useState('')
  const [store_ids, setStore_ids] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isCompanySearching, setIsCompanySearching] = useState(false)
  const [isStoreSearching, setIsStoreSearching] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  let setTimeoutID

  const fetchCompanyTemplates = () => {
    setIsLoading(true)
    getCompanyTemplates(token, companyId)
      .then(res => {
        setIsLoading(false)
        if (res) {
          updateCompanyTemplateListConnect(res.results, companyId)
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  const fetchStoreTemplates = store_id => {
    setIsLoading(true)
    getStoreTemplates(token, store_id)
      .then(res => {
        setIsLoading(false)
        if (res) {
          updateStoreTemplateListConnect(res.results, store_id)
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  const handleTemplateSearch = (value, templateType) => {
    clearTimeout(setTimeoutID)
    switch (templateType) {
      case 'Company':
        setIsCompanySearching(true)
        setCompanySearchValue(value)
        setTimeoutID = setTimeout(() => setIsCompanySearching(false), 500)
        break
      case 'Store':
        setIsStoreSearching(true)
        setStoreSearchValue(value)
        setTimeoutID = setTimeout(() => setIsStoreSearching(false), 500)
        break
      default:
        break
    }
  }

  const filterTemplateList = (value, templateType) => {
    let filteredTemplateList = []
    const templateList = templateType === 'Company' ? companyTemplates : storeTemplates
    if (value) {
      filteredTemplateList = templateList.filter(template => {
        return (
          template.name.toLowerCase().includes(value.toLowerCase()) ||
          template.message.toLowerCase().includes(value.toLowerCase())
        )
      })
    } else {
      return templateType === 'Company' ? companyTemplates : storeTemplates
    }

    return filteredTemplateList
  }

  const handleTemplateSearchBlur = templateType => {
    if (templateType === 'Company') {
      companySearchValue === 0 && setIsCompanySearching(false)
    }
    storeSearchValue === 0 && setIsStoreSearching(false)
  }

  const handleClearInputField = templateType => {
    if (templateType === 'Company') {
      setCompanySearchValue('')
      setIsCompanySearching(false)
    }
    setStoreSearchValue('')
    setIsStoreSearching(false)
  }

  const handleTemplateActivity = (emailTemplate, templateType) => {
    const editedTemplate = emailTemplate
    if (emailTemplate.active) {
      editedTemplate.active = false
    } else {
      editedTemplate.active = true
    }
    updateEmailTemplate(token, editedTemplate, editedTemplate.id)
      .then(res => {
        if (res) {
          editTemplateConnect(emailTemplate, editedTemplate, templateType)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    companyTemplates.length > 0 ? null : fetchCompanyTemplates()
    storeTemplates.length > 0 ? null : fetchStoreTemplates(defaultStoreId)
  }, [])

  useLayoutEffect(() => {
    const updateLayout = () => {
      setIsMobile(window.innerWidth <= 650)
    }
    window.addEventListener('resize', updateLayout)
    updateLayout()
    return () => window.removeEventListener('resize', updateLayout)
  }, [])

  const renderSearchBarDisclaimer = () => {
    return (
      <div className="search-disclaimer">
        In the search bars below you can search for a <b>specific</b> template. The search looks
        through the list of templates and matches the keywords entered with the <b>name</b> as well
        as the <b>message</b>. So you can filter templates by searching through their <b>names</b>{' '}
        or the actual <b>message</b>.
      </div>
    )
  }

  const renderSearchBar = templateType => {
    const isSearching = templateType === 'Company' ? companySearchValue : storeSearchValue
    return (
      <div className="searchTemplatesContainer">
        <input
          type="text"
          name="search"
          placeholder={`You can search for a specific ${templateType} template here`}
          className="searchTemplates"
          value={templateType === 'Company' ? companySearchValue : storeSearchValue}
          onBlur={() => handleTemplateSearchBlur(templateType)}
          onChange={e => handleTemplateSearch(e.target.value, templateType)}
        />
        <button
          className="inputCloseButton"
          data-searching={isSearching ? 'true' : ''}
          onClick={e => handleClearInputField(templateType)}
        >
          <IconX />
        </button>
      </div>
    )
  }

  const renderNoSearchResultsMessage = templateType => {
    return (
      <div className="noTemplatesError">
        <Warning />
        There are no {templateType} template names or messages that contain{' '}
        {templateType === 'Company' ? companySearchValue : storeSearchValue}.
      </div>
    )
  }

  const renderPrivacySlide = (emailTemplate, templateType) => {
    return (
      <div className="privacySlide" style={{ animation: ` 1500ms fadeIn`, alignItems: 'center' }}>
        <span
          className={`packetSendItalicStyle ${emailTemplate.active ? 'transparent' : ''}`}
          style={{ fontSize: '0.8em' }}
        ></span>
        <label className="switch">
          <input
            onChange={() => handleTemplateActivity(emailTemplate, templateType)}
            type="checkbox"
            checked={emailTemplate.active}
          />
          <span className="slider round" />
        </label>
      </div>
    )
  }

  const renderTableHeader = templateType => {
    if (isMobile) {
      return (
        <div className="templateHeader">
          <div className="templateInfo">Template Info</div>
          <div className="templateActions">Options</div>
        </div>
      )
    }
    return (
      <div className="templateHeader">
        <div className="templateName">Name</div>
        {templateType === 'Store' ? <div className="templateStore">Store</div> : null}
        <ToolTip
          onHover
          tip={
            <React.Fragment>
              This Toggle allows you to activate or deactivate this template.
              <br />
              Only active templates can be rendered in the packet send modals.
            </React.Fragment>
          }
        >
          <div className="templateActivityToggle">Enable/disable</div>
        </ToolTip>
        <div className="editTemplate">Edit</div>
        <div className="viewTemplate">View</div>
      </div>
    )
  }

  const renderTemplateRow = (emailTemplate, templateType, index) => {
    if (isMobile) {
      return (
        <div className="templateRow" key={index}>
          <div className="templateInfo">
            <div>{emailTemplate.name}</div>
            {templateType === 'Store' ? (
              <div>{emailTemplate.store ? emailTemplate.store : defaultStoreName}</div>
            ) : null}
          </div>
          <div className="templateActions">
            {renderPrivacySlide(emailTemplate, templateType)}
            <div className="editViewTemplate">
              <div
                className="editTemplate"
                onClick={() => {
                  showModalConnect(EDIT_EMAIL_TEMPLATE, {
                    selectedTemplate: emailTemplate,
                    storeName: emailTemplate.store ? emailTemplate.store : defaultStoreName,
                    templateType: templateType,
                  })
                }}
              >
                <EditIcon />
              </div>
              <div
                className="viewTemplate"
                onClick={() => {
                  showModalConnect(VIEW_EMAIL_TEMPLATE, {
                    selectedTemplate: emailTemplate,
                    storeName: emailTemplate.store ? emailTemplate.store : defaultStoreName,
                    templateType: templateType,
                  })
                }}
              >
                <EyeOpenIcon />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="templateRow" key={index}>
        <div className="templateName">{emailTemplate.name}</div>
        {templateType === 'Store' ? (
          <div className="templateStore">
            {emailTemplate.store ? emailTemplate.store : defaultStoreName}
          </div>
        ) : null}
        {renderPrivacySlide(emailTemplate, templateType)}
        <div
          className="editTemplate"
          onClick={() =>
            showModalConnect(EDIT_EMAIL_TEMPLATE, {
              selectedTemplate: emailTemplate,
              storeName: emailTemplate.store ? emailTemplate.store : defaultStoreName,
              templateType: templateType,
            })
          }
        >
          <EditIcon />
        </div>
        <div
          className="viewTemplate"
          onClick={() =>
            showModalConnect(VIEW_EMAIL_TEMPLATE, {
              selectedTemplate: emailTemplate,
              storeName: emailTemplate.store ? emailTemplate.store : defaultStoreName,
              templateType: templateType,
            })
          }
        >
          <EyeOpenIcon />
        </div>
      </div>
    )
  }

  const renderNoTemplatesMessage = templateType => {
    return (
      <div className="noTemplatesError">
        <Warning />
        There are no {templateType} email templates at this time. You can use the Add Template
        button to create one.
      </div>
    )
  }

  const renderCompanyTemplates = () => {
    const companyTemplateList = filterTemplateList(companySearchValue, 'Company')
    if (isCompanySearching) {
      return <LoadingSpinner loading wheel />
    }

    if (companySearchValue && companyTemplateList.length === 0) {
      return renderNoSearchResultsMessage('Company')
    }

    if (companyTemplateList.length === 0) {
      return renderNoTemplatesMessage('Company')
    }

    return (
      <React.Fragment>
        {renderTableHeader('Company')}
        {companyTemplateList.map((emailTemplate, index) => {
          return renderTemplateRow(emailTemplate, 'Company', index)
        })}
      </React.Fragment>
    )
  }

  const renderStoreTemplates = () => {
    const storeTemplateList = filterTemplateList(storeSearchValue, 'Store')
    if (isStoreSearching) {
      return <LoadingSpinner loading wheel />
    }

    if (storeSearchValue && storeTemplateList.length === 0) {
      return renderNoSearchResultsMessage('Store')
    }

    if (storeTemplateList.length === 0) {
      return renderNoTemplatesMessage('Store')
    }

    return (
      <React.Fragment>
        {renderTableHeader('Store')}
        {storeTemplateList.map((emailTemplate, index) => {
          return renderTemplateRow(emailTemplate, 'Store', index)
        })}
      </React.Fragment>
    )
  }

  const renderEmailTemplates = () => {
    if (isLoading) {
      return <LoadingSpinner loading wheel />
    }

    return (
      <div className="emailTemplates">
        {companyTemplates && storeTemplates && renderSearchBarDisclaimer()}

        <div className="companyTemplates">
          <h3>Company Templates</h3>
          {renderSearchBar('Company')}
          {renderCompanyTemplates()}
        </div>

        <div className="storeTemplates">
          <h3>Store Templates</h3>
          {renderSearchBar('Store')}
          {renderStoreTemplates()}
        </div>
      </div>
    )
  }

  return (
    <div align="center" className="white_box">
      <div id="basics-bloc">
        <h2>Email Templates</h2>
        <hr />

        <div className="infoBlurb">
          On this page you can create and edit the email templates used on packet shares.
        </div>

        <div className="emailTemplates-body">
          <div className="addTemplate">
            <button
              onClick={() =>
                showModalConnect(ADD_EMAIL_TEMPLATE, {
                  storeIds: store_ids,
                  companyId: companyId,
                  defaultStoreId: defaultStoreId,
                })
              }
            >
              <span>
                <IconAddItem className="addIcon" />
                Add Template
              </span>
            </button>
          </div>

          {renderEmailTemplates()}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  companyTemplates: state.emailTemplates.companyTemplates,
  storeTemplates: state.emailTemplates.storeTemplates,
  token: state.authentication.user.token,
  user_id: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  companyId:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_id,
  companyName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_name,
  defaultStoreId:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.default_store_id,
  defaultStoreName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.store_full_name,
  isStaff:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.is_staff,
})

const mapDispatchToProps = {
  showModalConnect: showModal,
  updateCompanyTemplateListConnect: updateCompanyTemplateList,
  updateStoreTemplateListConnect: updateStoreTemplateList,
  editTemplateConnect: editTemplate,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)
